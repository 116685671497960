import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface INfcIconSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function NfcIconSVG({
  color = 'var(--blue-800)',
  width = 40,
  height = 40,
}: INfcIconSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="1 1 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11_799)" fill={color}>
        <path d="M7.5 11.25A1.25 1.25 0 018.75 10h15a1.25 1.25 0 010 2.5h-15a1.25 1.25 0 01-1.25-1.25zm0 5A1.25 1.25 0 018.75 15h15a1.25 1.25 0 010 2.5h-15a1.25 1.25 0 01-1.25-1.25zm0 5A1.25 1.25 0 018.75 20h15a1.25 1.25 0 010 2.5h-15a1.25 1.25 0 01-1.25-1.25zm0 5A1.25 1.25 0 018.75 25h15a1.25 1.25 0 010 2.5h-15a1.25 1.25 0 01-1.25-1.25zm0 5A1.25 1.25 0 018.75 30h15a1.25 1.25 0 010 2.5h-15a1.25 1.25 0 01-1.25-1.25zM28.75 10a1.25 1.25 0 000 2.5h2.5a1.25 1.25 0 000-2.5h-2.5zm0 5a1.25 1.25 0 000 2.5h2.5a1.25 1.25 0 000-2.5h-2.5zm0 5a1.25 1.25 0 000 2.5h2.5a1.25 1.25 0 000-2.5h-2.5zm0 5a1.25 1.25 0 000 2.5h2.5a1.25 1.25 0 000-2.5h-2.5zm0 5a1.25 1.25 0 000 2.5h2.5a1.25 1.25 0 000-2.5h-2.5z" />
        <path d="M5.885 1.615a1.25 1.25 0 00-2.002.325l-1.25 2.5A1.25 1.25 0 002.5 5v32.5H1.25a1.25 1.25 0 100 2.5h37.5a1.25 1.25 0 000-2.5H37.5V5a1.25 1.25 0 00-.133-.56l-1.25-2.5a1.25 1.25 0 00-2-.325L32.5 3.233l-1.615-1.618a1.25 1.25 0 00-1.77 0L27.5 3.233l-1.615-1.618a1.25 1.25 0 00-1.77 0L22.5 3.233l-1.615-1.618a1.25 1.25 0 00-1.77 0L17.5 3.233l-1.615-1.618a1.25 1.25 0 00-1.77 0L12.5 3.233l-1.615-1.618a1.25 1.25 0 00-1.77 0L7.5 3.233 5.885 1.615zM5.343 4.61l1.274 1.275a1.25 1.25 0 001.768 0L10 4.268l1.615 1.617a1.25 1.25 0 001.77 0L15 4.268l1.615 1.617a1.25 1.25 0 001.77 0L20 4.268l1.615 1.617a1.25 1.25 0 001.77 0L25 4.268l1.615 1.617a1.25 1.25 0 001.77 0L30 4.268l1.615 1.617a1.25 1.25 0 001.77 0l1.273-1.275.342.685V37.5H5V5.295l.343-.685z" />
      </g>
      <defs>
        <clipPath id="clip0_11_799">
          <path fill={'var(--white)'} d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
