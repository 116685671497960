import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface INfeIconSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function NfeIconSVG({
  color = 'var(--white)',
  width = 40,
  height = 40,
}: INfeIconSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 -5 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_306_1340)">
        <path
          d="M31.7612 18.8389H26.607V23.3346"
          stroke={color}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M13.5423 30.8079V28.5747C13.5423 28.5747 15.99 26.7793 15.99 24.585V23.4902"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M7.02487 14.0515L3.76119 16.6059C2.60696 17.6422 2.12935 18.231 2.12935 19.7977V30.9883"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M0.497513 34.0004V30.8086H15.1841V34.0004"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M5.14427 22.191L11.6716 15.8319C13.1642 14.3722 15.6517 17.2234 13.8458 19.4129C12.9652 20.274 11.9204 21.4174 11.9204 21.4174V23.0133C11.9396 23.9654 11.6078 24.8924 10.9857 25.6249C10.3637 26.3575 9.4928 26.8466 8.53233 27.003"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M7.02487 20.4351V0.486572H31.5025V19.6371L27.4229 23.6268H11.9204"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M11.9204 6.07214H25.791"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M11.9204 9.26392H20.8955"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_306_1340">
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
