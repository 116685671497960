import React, { useEffect, useState } from 'react'
import { ModuloHome } from './moduloHome'
import {
  AreaSaibaMais,
  AreaTitulo,
  Conteudo,
  GridModulos,
  SaibaMaisTemp,
  Titulo,
} from './styles'
import { LineDivisionSVG } from '../Svg/LineDivisionSVG'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { IModulo } from '../GridModulos'
import { Loader } from '../Loader'

function CardModulos() {
  const [loading, setLoading] = useState<boolean>(true)
  const [modulos, setModulos] = useState<Array<IModulo>>()

  async function getModulos() {
    await axios
      .get('https://www.sistemacicom.com.br/solid/api_admcicom/module')
      .then(({ data }) => setModulos(data))
    setLoading(false)
  }

  useEffect(() => {
    getModulos()
  }, [])

  return (
    <Conteudo id="Modulos">
      <AreaTitulo>
        <Titulo>Veja os módulos disponíveis</Titulo>
        <LineDivisionSVG color={'var(--blue-600)'} />
      </AreaTitulo>

      {loading ? (
        <Loader />
      ) : (
        <GridModulos>
          {modulos &&
            modulos.map((mod) =>
              mod.mostrar_site === 'S' ? (
                <ModuloHome modulo={mod.nome} codIcone={mod.id} key={mod.id} />
              ) : (
                <></>
              ),
            )}
        </GridModulos>
      )}
      <AreaSaibaMais>
        <Link to="/modulos" className="link-botao">
          <SaibaMaisTemp>Saiba mais</SaibaMaisTemp>
        </Link>
      </AreaSaibaMais>
    </Conteudo>
  )
}

export { CardModulos }
