import styled from 'styled-components'

export const BoxShadow = styled.div`
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.3));
`

export const Container = styled.div`
  position: relative;
  border-top: 5px solid var(--blue-800);
  padding: 62px 16px 24px 16px;
  background: var(--white);
  width: 271px;
  height: 432px;
  /* polygon https://bennettfeely.com/clippy/ */
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 85% 100%, 0% 100%);

  @media (max-width: 360px) {
    width: 250px;
    height: 452px;
  }
`

export const DivIcone = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  height: auto;
  width: auto;
`
