import styled from 'styled-components'

export const ContainerFaleConosco = styled.div`
  background: var(--grey-100);
  width: 100%;
  padding: 50px 0;
  .Toastify__toast {
    font-size: 18px;
  }
`

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

export const Titulo = styled.div`
  font-weight: 600;
  font-size: 32px;
`

export const TextFaleConosco = styled.span`
  font-size: 18px;
  text-align: center;
  padding: 0px 16px;
`
export const AreaForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const Form = styled.form`
  font-size: 18px;
  display: grid;
  max-width: 1500px;
  grid-template-columns: 350px 350px 350px 350px;
  grid-template-rows: 63px 201px;
  grid-template-areas:
    'nome email telefone cnpj'
    'mensagem mensagem mensagem btnEnviar';
  grid-gap: 32px 24px;
  @media (max-width: 1500px) {
    max-width: 730px;
    grid-template-columns: 350px 350px;
    grid-template-rows: 63px 63px 201px 56px;
    grid-template-areas:
      'nome email'
      'telefone cnpj'
      'mensagem mensagem'
      'btnEnviar btnEnviar';
    grid-gap: 32px 24px;
  }
  @media (max-width: 800px) {
    max-width: 350px;
    grid-template-columns: 350px;
    grid-template-rows: repeat(4, 63px) 160px 56px;
    grid-template-areas:
      'nome'
      'email'
      'telefone'
      'cnpj'
      'mensagem'
      'btnEnviar';
  }
  @media (max-width: 400px) {
    max-width: 300px;
    grid-template-columns: 300px;
    grid-template-rows: repeat(4, 63px) 160px 56px;
    grid-template-areas:
      'nome'
      'email'
      'telefone'
      'cnpj'
      'mensagem'
      'btnEnviar';
  }
  @media (max-width: 360px) {
    max-width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, 63px) 160px 56px;
    grid-template-areas:
      'nome'
      'email'
      'telefone'
      'cnpj'
      'mensagem'
      'btnEnviar';
    padding: 0 16px;
  }
`

export const InputNomeArea = styled.div`
  grid-area: nome;
  width: 100%;
`
export const InputEmailArea = styled.div`
  grid-area: email;
  width: 100%;
`
export const InputTelefoneArea = styled.div`
  grid-area: telefone;
  width: 100%;
`
export const InputCNPJArea = styled.div`
  grid-area: cnpj;
  width: 100%;
`

export const TextAreaContainer = styled.label`
  grid-area: mensagem;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
`

export const TextArea = styled.textarea`
  outline: none;
  width: 100%;
  height: 172px;
  margin-top: 8px;
  padding: 10px 16px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: var(--white);
  font-size: 18px;
  font-family: 'Inter';
  resize: none;

  @media (max-width: 800px) {
    height: 131px;
  }

  :focus {
    border: 2px solid var(--blue-800);
  }

  ::placeholder {
    font-size: 18px;
    color: var(--grey-500);
    letter-spacing: 0.5px;
    font-family: 'Inter';
  }
`
export const BtnEnviarArea = styled.div`
  grid-area: btnEnviar;
  margin: 0 auto;
  width: 200px;
  @media (min-width: 1500px) {
    margin: auto auto;
  }
`
