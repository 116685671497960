import styled from 'styled-components'

export const Conteudo = styled.div`
  position: relative;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 85px;
  height: (100vh -165px);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1180px) {
    padding-top: 10px;
  }
  @media (min-height: 940px) {
    padding-bottom: 115px;
  }
`
// Areas ===============================================
export const GridDownloads = styled.div`
  display: grid;
  grid-template-columns: 540px 540px;
  grid-template-rows: auto;
  grid-template-areas: 'texto imagem';
  grid-gap: 24px 100px;
  @media (max-width: 1350px) {
    grid-gap: 24px 50px;
  }
  @media (max-width: 1180px) {
    grid-template-areas: 'imagem' 'texto';
    grid-template-columns: 540px;
    grid-template-rows: auto;
  }
  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
`
export const AreaTexto = styled.div`
  grid-area: texto;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  @media (max-width: 1180px) {
    align-items: center;
  }
  @media (max-width: 800px) {
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }
`
export const AreaTitulo = styled.div`
  height: 180px;
  width: 100%;
  @media (max-width: 1180px) {
    text-align: center;
    height: auto;
  }
  @media (max-width: 600px) {
    text-align: left;
  }
`
export const AreaSubtitulo = styled.div`
  height: auto;
  width: 100%;
  @media (max-width: 1180px) {
    text-align: center;
  }
  @media (max-width: 600px) {
    text-align: left;
  }
`
export const AreaBotoes = styled.div`
  margin-top: 16px;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1180px) {
    padding: 0px 16px;
    justify-content: center;
    gap: 24px;
  }
  @media (max-width: 600px) {
    height: 136px;
    padding: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`
export const DivBotao = styled.a`
  text-decoration: none;
  width: 250px;

  @media (max-width: 600px) {
    width: 100%;
  }
`
export const AreaAcessoRemoto = styled.div`
  margin-top: 80px;
  padding: 32px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.2);
  @media (max-width: 400px) {
    padding: 16px 4px 16px 4px;

    height: auto;
    width: 100%;
  }
`
export const AreaTextoAcessoRemoto = styled.div`
  text-align: center;
  @media (max-width: 400px) {
    padding: 0px 8px;
  }
`
export const AreaIconesAcessoRemoto = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 32px 16px;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 420px) {
    flex-direction: column;
    gap: 32px 16px;

    align-items: center;
  }
`
export const LinkIconeAcessoRemoto = styled.a`
  width: auto;
  height: 36px;
  display: flex;
  align-items: center;

  cursor: pointer;
`
export const DivIconeAcessoRemoto = styled.div`
  width: auto;
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & .kHelpDeskSVG {
    margin-top: 8px;
  }

  &:hover .TeamViewerColor {
    transition: fill 0.3s;
    fill: #0064c8;
  }
  &:hover .kHelpDesk {
    transition: fill 0.3s;
    fill: #04b476;
  }
  &:hover .kHelpDesk.k {
    transition: fill 0.3s;
    fill: #fe3c54;
  }
`

// Texto ===============================================

export const Titulo = styled.p`
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  color: var(--black);
  @media (max-width: 800px) {
    font-size: 36px;
    line-height: 36px;
  }
  @media (max-width: 600px) {
    font-weight: 500;

    font-size: 24px;
    line-height: 36px;
  }
`
export const Destaque = styled.b`
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  color: var(--blue-600);
  @media (max-width: 800px) {
    font-size: 36px;
    line-height: 36px;
  }
  @media (max-width: 600px) {
    font-weight: 600;

    font-size: 24px;
    line-height: 36px;
  }
`
export const Subtitulo = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 28.8px;
  color: var(--black);
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 20px;
  }
`
export const TextoAcessoRemoto = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  color: var(--grey-200);
`
// Imagem ==============================================
export const Imagem = styled.img`
  grid-area: imagem;
  width: 100%;
  height: auto;
`
