import React from 'react'
import LazyLoad from 'react-lazyload'

import {
  ConteudoTop,
  GridTop,
  AreaImagemTop,
  ImagemTop,
  TextoPrincipalTop,
  TextoSecundarioTop,
  Span,
  AreaButtonsTop,
  DivButtonTop,
  AreaTextoTop,
} from './styles'
import { CloudDownloadSVG } from '../Svg/CloudDownloadSVG'
import { PlayCircleFilledSVG } from '../Svg/PlayCircleFilledSVG'
import topHome from '../../assets/imgs/topHome.png'
import { Button } from '../Button'
import { useNavigate } from 'react-router-dom'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gtag: any
  }
}

function TopHome() {
  const navigate = useNavigate()
  function redirect(divID: string) {
    navigate('/home' + divID)
  }

  function handleDownloadClick() {
    window.gtag('event', 'conversion', {
      send_to: 'AW-992531803/b_uHCO3AlwIQ26qj2QM',
      value: 1.0,
      currency: 'BRL',
      transaction_id: '',
    })

    window.open('https://cicom.com.br/sistemas/Instal_mecauto.exe', '_self')
  }

  return (
    <ConteudoTop id="Top">
      <GridTop>
        <AreaTextoTop>
          <TextoPrincipalTop>
            Gerencie sua empresa do setor automotivo com o sistema
            <Span> MECAUTO</Span>
          </TextoPrincipalTop>
          <TextoSecundarioTop>
            Teste durante 30 dias gratuitamente, com
            <b> TOTAL SUPORTE</b>
          </TextoSecundarioTop>
          <AreaButtonsTop>
            <DivButtonTop onClick={handleDownloadClick} href="#">
              <Button
                buttonSize="large"
                buttonType="primary"
                leftIcon={<CloudDownloadSVG />}
                caption="Quero testar agora"
              />
            </DivButtonTop>
            <DivButtonTop>
              <Button
                buttonSize="large"
                buttonType="secondary"
                leftIcon={<PlayCircleFilledSVG />}
                caption="Conheça o sistema"
                onClick={() => redirect('#gerencieFacil')}
              />
            </DivButtonTop>
          </AreaButtonsTop>
        </AreaTextoTop>
        <AreaImagemTop>
          <LazyLoad height={470} once>
            <ImagemTop src={topHome} />
          </LazyLoad>
        </AreaImagemTop>
      </GridTop>
    </ConteudoTop>
  )
}

export { TopHome }
