import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IExclamationCircleSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function ExclamationCircleSVG({
  color = 'var(--blue-600)',
  width = 24,
  height = 24,
}: IExclamationCircleSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_278_920)">
        <path
          d="M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
          fill={color}
        />
        <path
          d="M10.5029 16.5C10.5029 16.303 10.5417 16.108 10.6171 15.926C10.6925 15.744 10.803 15.5786 10.9423 15.4393C11.0816 15.3 11.2469 15.1896 11.4289 15.1142C11.6109 15.0388 11.8059 15 12.0029 15C12.1999 15 12.395 15.0388 12.577 15.1142C12.7589 15.1896 12.9243 15.3 13.0636 15.4393C13.2029 15.5786 13.3134 15.744 13.3887 15.926C13.4641 16.108 13.5029 16.303 13.5029 16.5C13.5029 16.8978 13.3449 17.2793 13.0636 17.5607C12.7823 17.842 12.4008 18 12.0029 18C11.6051 18 11.2236 17.842 10.9423 17.5607C10.661 17.2793 10.5029 16.8978 10.5029 16.5ZM10.6499 7.49249C10.63 7.30325 10.65 7.11193 10.7087 6.93093C10.7675 6.74994 10.8636 6.58331 10.9909 6.44187C11.1182 6.30043 11.2738 6.18733 11.4476 6.10992C11.6215 6.0325 11.8096 5.99249 11.9999 5.99249C12.1902 5.99249 12.3784 6.0325 12.5522 6.10992C12.726 6.18733 12.8817 6.30043 13.009 6.44187C13.1362 6.58331 13.2324 6.74994 13.2911 6.93093C13.3499 7.11193 13.3699 7.30325 13.3499 7.49249L12.8249 12.753C12.8073 12.9597 12.7127 13.1522 12.56 13.2924C12.4072 13.4327 12.2073 13.5106 11.9999 13.5106C11.7925 13.5106 11.5927 13.4327 11.4399 13.2924C11.2871 13.1522 11.1926 12.9597 11.1749 12.753L10.6499 7.49249Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_278_920">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
