import { BackupNuvemIconSVG } from '../Svg/BackupNuvemIconSVG'
import { EstoqueMultilojasSVG } from '../Svg/EstoqueMultilojasSVG'
import { FinanceiroIconSVG } from '../Svg/FinanceiroIconSVG'
import { FotosIconSVG } from '../Svg/FotosIconSVG'
import { MercadoLivreIconSVG } from '../Svg/MercadoLivreIconSVG'
import { NfcIconSVG } from '../Svg/NfcIconSVG'
import { NfeIconSVG } from '../Svg/NfeIconSVG'
import { NfsIconSVG } from '../Svg/NfsIconSVG'
import { OficinaIconSVG } from '../Svg/OficinaIconSVG'
import { SatIconSVG } from '../Svg/SatIconSVG'
import { VendasIconSVG } from '../Svg/VendasIconSVG'
import { WhatsappIconSVG } from '../Svg/WhatsappIconSVG'

interface IProps {
  codeModulo: number
  color?: string
}

const IconsModulos = ({ codeModulo, color }: IProps) => {
  const Icon = (code: number, color: string = 'var(--blue-800)') => {
    switch (code) {
      case 6:
        return <OficinaIconSVG width={50} height={50} color={color} />
      case 5:
        return <VendasIconSVG width={46} height={46} color={color} />
      case 4:
        return <FinanceiroIconSVG width={44} height={44} color={color} />
      case 14:
        return <WhatsappIconSVG width={44} height={44} color={color} />
      case 1:
        return <NfeIconSVG width={50} height={50} color={color} />
      case 2:
        return <NfcIconSVG width={46} height={40} color={color} />
      case 7:
        return <NfsIconSVG width={46} height={40} color={color} />
      case 3:
        return <SatIconSVG width={45} height={45} color={color} />
      case 9:
        return <MercadoLivreIconSVG width={60} height={60} color={color} />
      case 16:
        return <BackupNuvemIconSVG width={54} height={54} color={color} />
      case 18:
        return <FotosIconSVG width={62} height={62} color={color} />
      case 13:
        return <EstoqueMultilojasSVG width={58} height={58} color={color} />
    }
  }

  return <>{color ? Icon(codeModulo, color) : Icon(codeModulo)}</>
}

export { IconsModulos }
