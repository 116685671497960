import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IWhatsappIconSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function WhatsappIconSVG({
  color = 'var(--blue-800)',
  width = 40,
  height = 40,
}: IWhatsappIconSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_211_3000)">
        <path
          d="M34.002 5.815A19.635 19.635 0 0019.985 0C9.068 0 .17 8.895.16 19.815c0 3.498.915 6.9 2.643 9.913L0 40l10.51-2.755a19.833 19.833 0 009.475 2.413h.01c10.92 0 19.815-8.895 19.825-19.825A19.746 19.746 0 0034 5.815h.002zM19.985 36.303a16.432 16.432 0 01-8.39-2.3l-.6-.36-6.235 1.635 1.665-6.083-.39-.627a16.4 16.4 0 01-2.518-8.763c0-9.065 7.393-16.46 16.478-16.46a16.4 16.4 0 0111.65 4.828 16.391 16.391 0 014.82 11.65c-.01 9.097-7.403 16.48-16.48 16.48zm9.038-12.335c-.493-.248-2.925-1.445-3.383-1.615-.455-.163-.788-.248-1.113.247-.332.493-1.282 1.615-1.567 1.938-.285.332-.58.37-1.075.125-.493-.25-2.09-.77-3.98-2.463-1.475-1.312-2.463-2.937-2.758-3.43-.284-.495-.027-.76.22-1.007.218-.22.493-.58.74-.865.25-.285.333-.495.495-.825.163-.335.085-.62-.037-.868-.125-.247-1.113-2.69-1.53-3.675-.4-.972-.808-.837-1.113-.85-.285-.017-.617-.017-.95-.017a1.821 1.821 0 00-1.322.617c-.455.495-1.728 1.693-1.728 4.135 0 2.443 1.775 4.79 2.025 5.123.246.332 3.486 5.33 8.458 7.48 1.175.512 2.1.815 2.822 1.045 1.188.38 2.26.322 3.116.2.95-.145 2.927-1.2 3.345-2.358.41-1.16.41-2.15.285-2.357-.123-.21-.456-.333-.95-.58z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_211_3000">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
