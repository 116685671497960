import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  :root {
    --black: #212121;
    --white: #FCFCFF;
    
    --blue-100: #5887D8;
    --blue-300: #1766FF;
    --blue-600: #3761AB;
    --blue-700: #2F5CAA;
    --blue-800: #2C4E89;
    --blue-830: #1c3a63;
    --blue-850: #183154;
    --blue-850-90: #183154E5;
    /* blue-850-90 é o blue-850 com opacidade, E5 = 90% */
    --blue-900: #132746;
    --blue-1000: #133240;

    
    --grey-100: #F5F7FA;
    --grey-150: #E1E1E1;
    --grey-200: #C8C8C8;
    --grey-300: #ABBED1;
    --grey-400: #757095;
    --grey-500: #A6A6A6;
    --grey-600: #4D4D4D;

    --red-100: #FFF1F0;
    --red-300: #E01507;

    --green-100: #F1FBF8;
    --green-300: #009262;
    --green-600: #1B6E53;

    --yellow-100: #ffbb33;
    --yellow-200: #ffb31a;
    --yellow-300: #FFAE11;

    /* cores do linear gradient middle nossa empresa */
    --blue-sky: #EEF5FF;
    --blue-sky-300-0: #ABCEFF00;

    /*color Toastify */
    --toastify-color-success: var(--blue-850);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-toast-background: var(--grey-100);
    --toastify-font-family: 'Inter';
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-toast-width: 320px;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html{
    height:100vh;
  }
  body {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    scroll-behavior: smooth;
    font-display: swap;
  }


/* efeitos dos menu itens do menu(header) */
.nav-link{
    transition: 0.2s;
    text-decoration: none;
}

.nav-link:hover{
    color: white;
    text-decoration: underline;
    font-weight: 800;
}
.nav-link_selected{
    color: white;
    font-weight: 800;
}

/* classe para os links nos botões não ficarem com underline */
.link-botao{
  text-decoration: none;
}
`
