import styled from 'styled-components'
import topPageModulos from '../../assets/imgs/topPageModulos.jpg'

export const ContainerTop = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 70px;
  width: 100%;
  background: url(${topPageModulos});
  background-color: rgb(38, 59, 96);
  background-size: cover;
`

export const ContainerTextTop = styled.div`
  width: 692px;
  height: 139px;
  color: var(--white);
  text-align: center;

  @media (max-width: 700px) {
    width: 550px;
  }

  @media (max-width: 600px) {
    width: 328px;
    height: 253px;
  }

  @media (max-width: 360px) {
    width: 240px;
    height: 253px;
  }
`

export const Titulo = styled.h1`
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 25px;

  @media (max-width: 600px) {
    font-size: 24px;
  }
`

export const Text = styled.span`
  @media (max-width: 600px) {
    font-size: 14px;
  }
`
