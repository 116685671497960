import { NoteModulo } from '../NoteModulos'
import { Container, ContainerGrid, Text, Titulo } from './styles'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Loader } from '../Loader'

export interface IModulo {
  cod_modulo: number
  nome: string
  id: number
  sigla: string
  mostrar_site: string
  descricao: string
}

const GridModulos = () => {
  const [modulos, setModulos] = useState<Array<IModulo>>()
  const [loading, setLoading] = useState<boolean>(true)

  async function getModulos() {
    await axios
      .get('https://www.sistemacicom.com.br/solid/api_admcicom/module')
      .then(({ data }) => setModulos(data))
    setLoading(false)
  }

  useEffect(() => {
    getModulos()
  }, [])

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <ContainerGrid>
          {modulos &&
            modulos.map((modulo) =>
              modulo.mostrar_site === 'S' ? (
                <NoteModulo
                  id={modulo.sigla}
                  codeIcon={modulo.id}
                  key={modulo.id}
                >
                  <Titulo>{modulo.nome}</Titulo>
                  <Text>{modulo.descricao}</Text>
                </NoteModulo>
              ) : (
                <></>
              ),
            )}
        </ContainerGrid>
      )}
    </Container>
  )
}

export { GridModulos }
