import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background: var(--grey-100);
  padding: 50px 62px 50px 62px;

  @media (max-width: 1024px) {
    padding: 50px 16px 50px 16px;
  }

  @media (max-width: 360px) {
    padding: 50px 0 50px 0;
  }
`

export const ContainerTitulo = styled.div`
  margin: 0 auto;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  margin-bottom: 48px;

  span {
    display: block;
    font-weight: 400;
    font-size: 18px;
    color: var(--grey-600);
    margin-top: 16px;
  }
  @media (max-width: 320px) {
    margin: 0 2px 24px 2px;
  }
`
export const ContainerConteudo = styled.div`
  max-width: 1156px;
  display: grid;
  grid-template-columns: 637px 479px;
  grid-template-rows: 148px 170px 19px;
  margin: 0 auto;
  grid-gap: 24px 48px;
  grid-template-areas:
    'video assista'
    'video download'
    'video link';

  @media (max-width: 1279px) {
    max-width: 890px;
    grid-template-columns: 500px 350px;
    grid-template-rows: 166px auto 19px;
  }
  @media (max-width: 1024px) {
    max-width: 640px;
    grid-template-columns: 637px;
    grid-template-rows: auto;
    grid-template-areas:
      'assista'
      'video'
      'download'
      'link';
  }
  @media (max-width: 670px) {
    max-width: 330px;
    grid-template-columns: 328px;
    grid-template-rows: auto;
    grid-template-areas:
      'assista'
      'video'
      'download'
      'link';
  }
  @media (max-width: 360px) {
    max-width: 300px;
    grid-template-columns: 300px;
    grid-template-rows: auto;
    grid-template-areas:
      'assista'
      'video'
      'download'
      'link';
  }
  @media (max-width: 320px) {
    max-width: 250px;
    grid-template-columns: 250px;
    grid-template-rows: auto;
    grid-template-areas:
      'assista'
      'video'
      'download'
      'link';
  }
`

export const AssistaArea = styled.div`
  grid-area: assista;
`
export const DownloadArea = styled.div`
  grid-area: download;
`
export const LinkArea = styled.div`
  grid-area: link;
`

export const ContainerGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas:
    'containerIcon titulo'
    'containerIcon descricao';
  gap: 16px 24px;

  .containerIcon {
    grid-area: containerIcon;
  }

  /* @media (max-width: 1279px) {
    width: 350px;
  }

  @media (max-width: 1024px) {
    width: 479px;
  }

  @media (max-width: 670px) {
    width: 328px;
  }

  @media (max-width: 360px) {
    width: 300px;
  }
  @media (max-width: 320px) {
    width: 250px;
  } */
`
export const TituloGrid = styled.div`
  grid-area: titulo;
  font-size: 24px;
  font-weight: 700;
  @media (max-width: 320px) {
    font-size: 20px;
  }
`
export const DescricaoGrid = styled.div`
  grid-area: descricao;
  font-weight: 500;
  color: var(--grey-500);
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 320px) {
    font-size: 16px;
  }
`

export const Download = styled.div`
  width: 115px;
  height: 19px;
  margin: 0 auto;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;

  a {
    color: var(--blue-900);
    text-decoration: underline;
  }
`
