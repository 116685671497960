import React from 'react'
import { TopDownloads } from '../components/TopDownloads'

function Downloads() {
  return (
    <>
      <TopDownloads />
    </>
  )
}

export { Downloads }
