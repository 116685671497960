import { Note } from '../Note'
import { PlayCircleFilledSVG } from '../Svg/PlayCircleFilledSVG'
import { DownloadSVG } from '../Svg/DownloadSVG'
import {
  AssistaArea,
  Container,
  ContainerConteudo,
  ContainerGrid,
  ContainerTitulo,
  DescricaoGrid,
  Download,
  DownloadArea,
  LinkArea,
  TituloGrid,
} from './styles'
import { VideoYouTube } from '../VideoYouTube'
import { useState } from 'react'
import { FakeVideoYouTube } from '../FakeYouTube'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gtag: any
  }
}

const Gerencie = () => {
  const [showVideo, setShowVideo] = useState(false)

  const handleMouseEnter = () => {
    setShowVideo(true)
  }

  function handleDownloadClick() {
    window.gtag('event', 'conversion', {
      send_to: 'AW-992531803/b_uHCO3AlwIQ26qj2QM',
      value: 1.0,
      currency: 'BRL',
      transaction_id: '',
    })

    window.open('https://cicom.com.br/sistemas/Instal_mecauto.exe', '_self')
  }

  return (
    <Container id="gerencieFacil">
      <ContainerTitulo>
        Gerencie sua empresa de forma prática e fácil
        <span>Auto-peças, oficinas, funilarias, motopeças, etc.</span>
      </ContainerTitulo>

      <ContainerConteudo onMouseEnter={handleMouseEnter}>
        {showVideo && <VideoYouTube />}
        {!showVideo && <FakeVideoYouTube />}
        <AssistaArea>
          <ContainerGrid>
            <Note padding="0px 10px">
              <PlayCircleFilledSVG
                width={36}
                height={36}
                color="var(--white)"
              />
            </Note>
            <TituloGrid className="titulo">
              Assista nosso vídeo de apresentação
            </TituloGrid>
            <DescricaoGrid className="descricao">
              Veja as principais funções do sistema Mecauto, desenvolvido
              exclusivamente para o setor automotivo.
            </DescricaoGrid>
          </ContainerGrid>
        </AssistaArea>
        <DownloadArea>
          <ContainerGrid>
            <Note padding="0px 10px">
              <DownloadSVG height={36} width={36} color="var(--white)" />
            </Note>
            <TituloGrid className="titulo">
              Faça o download do nosso sistema
            </TituloGrid>
            <DescricaoGrid className="descricao">
              Você pode testar durante 30 dias gratuitamente. E o melhor, você
              terá total suporte nesse período e após a aquisição da licença.
            </DescricaoGrid>
          </ContainerGrid>
        </DownloadArea>
        <LinkArea>
          <Download>
            <a href="#" onClick={handleDownloadClick}>
              Baixe agora
            </a>
          </Download>
        </LinkArea>
      </ContainerConteudo>
    </Container>
  )
}

export { Gerencie }
