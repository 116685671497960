import { ReactNode } from 'react'
import { ContainerTop, ContainerTextTop, Titulo } from './styles'

interface ITopClientesProps {
  children: ReactNode
}

const TopClientes = ({ children }: ITopClientesProps) => {
  return (
    <ContainerTop>
      <ContainerTextTop>
        <Titulo>Veja alguns de nossos clientes por todo Brasil</Titulo>
        {children}
      </ContainerTextTop>
    </ContainerTop>
  )
}

export { TopClientes }
