import React from 'react'
import { Conteudo, Td, Tabela, THead, Tr, TBody, Lista } from './styles'
import { TextoResposta } from '../styles'

function RespostaRequisitosSistema() {
  return (
    <Conteudo>
      <TextoResposta>
        O sistema Mecauto funciona em rede local, ou seja, o sistema é{' '}
        <b>instalado em um computador</b>, que chamamos de <b>SERVIDOR</b>, e
        pode ser acessado por outros <b>computadores</b>, que chamamos de{' '}
        <b>ESTAÇÕES</b>.
      </TextoResposta>
      <Tabela>
        <THead>
          <p>
            SERVIDOR{' '}
            <span>(computador principal, onde o Mecauto é instalado)</span>
          </p>
        </THead>
        <TBody>
          <Tr>
            <Td gridArea="col1" isSubtitle={true}>
              <p>Equipamento</p>
            </Td>
            <Td gridArea="col2" isSubtitle={true}>
              <p>Requisitos minímos</p>
            </Td>
            <Td gridArea="col3" isSubtitle={true}>
              <p>Requisitos recomendados</p>
            </Td>
          </Tr>
          {/* Processador */}
          <Tr>
            <Td gridArea="col1">
              <p>Processador</p>
            </Td>
            <Td gridArea="col2">
              <p>Intel i3 ou similar</p>
            </Td>
            <Td gridArea="col3">
              <Lista>
                <li>
                  <p>Se utilizar em até 6 computadores:</p>
                  <p>Intel i5 ou similar ou superior</p>
                </li>
                <li>
                  <p>Se utilizar em mais de 6 computadores:</p>
                  <p>Intel i7 ou similar ou superior</p>
                </li>
              </Lista>
            </Td>
          </Tr>
          {/* Memória RAM */}
          <Tr>
            <Td gridArea="col1">
              <p>Memória RAM</p>
            </Td>
            <Td gridArea="col2">
              <Lista>
                <li>
                  <p>Até 3 computadores: 4GB </p>
                </li>
                <li>
                  <p>4 a 6 computadores: 6GB</p>
                </li>
                <li>
                  <p>6 ou mais computadores: 8GB </p>
                </li>
              </Lista>
              <span>Acrescentar 1GB de RAM por computador</span>
            </Td>
            <Td gridArea="col3">
              <Lista>
                <li>
                  <p>Até 3 computadores: 6GB </p>
                </li>
                <li>
                  <p>4 a 6 computadores: 8GB</p>
                </li>
                <li>
                  <p>6 ou mais computadores: 16GB </p>
                </li>
              </Lista>
              <span>Acrescentar 2GB de RAM por computador</span>
            </Td>
          </Tr>
          {/* Armazenamento */}
          <Tr>
            <Td gridArea="col1">
              <p>Armazenamento</p>
            </Td>
            <Td gridArea="col2">
              <p>HD: 2GB de espaço livre</p>
            </Td>
            <Td gridArea="col3">
              <p>SSD: 4GB de espaço livre</p>
            </Td>
          </Tr>
          {/* Sistema Operacional SO | OS */}
          <Tr>
            <Td gridArea="col1">
              <p>Sistema operacional</p>
            </Td>
            <Td gridArea="col2">
              <p>Windows 8</p>
            </Td>
            <Td gridArea="col3">
              <p>Windows 10 ou superior</p>
            </Td>
            {/* Resolução de Monitor */}
          </Tr>
          <Tr>
            <Td gridArea="col1">
              <p>Resolução de Monitor</p>
            </Td>
            <Td gridArea="col2">
              <p>1024x768</p>
            </Td>
            <Td gridArea="col3">
              <p>1920x1080 ou superior</p>
            </Td>
            {/* Rede */}
          </Tr>
          <Tr>
            <Td gridArea="col1">
              <p>Rede</p>
            </Td>
            <Td gridArea="col2">
              <p>Cabeada</p>
              <span>
                Nota: o Wi-fi pode deixar a utilização do sistema muito lenta
              </span>
            </Td>
            <Td gridArea="col3">
              <p>Cabeada 1000 mbps</p>
              <span>Nota: não recomendamos o uso do Wi-fi</span>
            </Td>
          </Tr>
        </TBody>
      </Tabela>
      {/* ======= Estação ======= */}
      <Tabela>
        <THead>
          <p>Estação</p>
        </THead>
        <TBody>
          <Tr>
            <Td gridArea="col1" isSubtitle>
              <p>Equipamento</p>
            </Td>
            <Td gridArea="col2" isSubtitle>
              <p>Requisitos minímos</p>
            </Td>
            <Td gridArea="col3" isSubtitle>
              <p>Requisitos recomendados</p>
            </Td>
          </Tr>
          {/* Processador */}
          <Tr>
            <Td gridArea="col1">
              <p>Processador</p>
            </Td>
            <Td gridArea="col2">
              <p>Intel i3 ou similar</p>
            </Td>
            <Td gridArea="col3">
              <p>Intel i5 ou similar ou superior</p>
            </Td>
          </Tr>
          {/* Memória RAM */}
          <Tr>
            <Td gridArea="col1">
              <p>Memória RAM</p>
            </Td>
            <Td gridArea="col2">
              <p>4GB </p>
            </Td>
            <Td gridArea="col3">
              <p>8GB</p>
            </Td>
          </Tr>
          {/* Armazenamento */}
          <Tr>
            <Td gridArea="col1">
              <p>Armazenamento</p>
            </Td>
            <Td gridArea="col2">
              <p>HD: 1GB de espaço livre</p>
            </Td>
            <Td gridArea="col3">
              <p>SSD: 2GB de espaço livre</p>
            </Td>
          </Tr>
          {/* Sistema Operacional SO | OS */}
          <Tr>
            <Td gridArea="col1">
              <p>Sistema operacional</p>
            </Td>
            <Td gridArea="col2">
              <p>Windows 8</p>
            </Td>
            <Td gridArea="col3">
              <p>Windows 10 ou superior</p>
            </Td>
          </Tr>
          {/* Resolução de Monitor */}
          <Tr>
            <Td gridArea="col1">
              <p>Resolução de Monitor</p>
            </Td>
            <Td gridArea="col2">
              <p>1024x768</p>
            </Td>
            <Td gridArea="col3">
              <p>1920x1080 ou superior</p>
            </Td>
          </Tr>
          {/* Rede */}
          <Tr>
            <Td gridArea="col1">
              <p>Rede</p>
            </Td>
            <Td gridArea="col2">
              <p>Cabeada</p>

              <span>
                Nota: o Wi-fi pode deixar a utilização do sistema muito lenta
              </span>
            </Td>
            <Td gridArea="col3">
              <p>Cabeada 1000 mbps</p>
              <span>Nota: não recomendamos o uso do Wi-fi</span>
            </Td>
          </Tr>
        </TBody>
      </Tabela>
    </Conteudo>
  )
}

export { RespostaRequisitosSistema }
