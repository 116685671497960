import { Container } from './styles'
import fakeyoutube from '../../assets/imgs/fakeYoutube.jpg'
import LazyLoad from 'react-lazyload'

export const FakeVideoYouTube = () => {
  return (
    <Container>
      <LazyLoad height={385} once>
        <img src={fakeyoutube} alt="Videosobre o sistema" />
      </LazyLoad>
    </Container>
  )
}
