import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IArrowUpSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function ArrowUpSVG({
  color = 'var(--black)',
  width = 18,
  height = 10,
}: IArrowUpSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 9.79995L9.00001 3.79995L3.00001 9.79995L0.600006 8.59995L9.00001 0.199951L17.4 8.59995L15 9.79995Z"
        fill={color}
      />
    </svg>
  )
}
