import React, { useState, useEffect } from 'react'
import { Cabecalho, DivLogo, IconHamburguer } from './styles'
import { Logo } from './Logo'
import { Menu } from './Menu/Index'
import iconHamb from '../../assets/imgs/hamburguerIcon.svg'
import clickedIconHamb from '../../assets/imgs/clickedHamburguerIcon.svg'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Footer } from '../Footer'

function Header() {
  const [aparecerMenu, setAparecerMenu] = useState<Boolean>(false)
  const [routefaleConosco, setRouteFaleConosco] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  function toggleMenu() {
    setAparecerMenu(!aparecerMenu)
  }

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/home')
    }
    if (location.pathname === '/faleconosco') {
      setRouteFaleConosco(true)
    } else {
      setRouteFaleConosco(false)
    }
  }, [location.pathname, navigate])

  useEffect(() => {
    setAparecerMenu(false)
  }, [location.pathname])

  return (
    <>
      <Cabecalho aparecerMenu={aparecerMenu} id="header">
        <DivLogo aparecerMenu={aparecerMenu}>
          <Link to="/home">
            <Logo />
          </Link>
        </DivLogo>
        <Menu aparecerMenu={aparecerMenu} />
        <IconHamburguer
          aparecerMenu={aparecerMenu}
          src={aparecerMenu ? clickedIconHamb : iconHamb}
          onClick={toggleMenu}
        />
      </Cabecalho>
      <Outlet />
      {!routefaleConosco && <Footer />}
    </>
  )
}

export { Header }
