import { GridModulos } from '../components/GridModulos'
import { TopModulos } from '../components/TopModulos'

const Modulos = () => {
  return (
    <>
      <TopModulos />
      <GridModulos />
    </>
  )
}

export { Modulos }
