import styled from 'styled-components'

interface IConteudoTabelaProps {
  gridArea: 'col1' | 'col2' | 'col3'
  isSubtitle?: boolean
}

export const Conteudo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Tabela = styled.table`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  border-radius: 5px 5px 0 0;
  @media (max-width: 600px) {
    padding-bottom: 8px;
    overflow-x: scroll;
    grid-template-columns: 740px;
  }
  @media (max-width: 400px) {
    grid-template-columns: 480px;
  }
`

export const THead = styled.thead`
  display: flex;
  justify-content: center;
  background-color: var(--blue-800);
  border-radius: 5px 5px 0 0;
  & p {
    padding: 8px 16px;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
    @media (max-width: 900px) {
      font-size: 16px;
    }
    @media (max-width: 400px) {
      font-size: 14px;
    }
  }
  & span {
    font-size: 18px;
    font-weight: 400;
    color: var(--white);
    @media (max-width: 900px) {
      font-size: 16px;
    }
    @media (max-width: 400px) {
      font-size: 14px;
    }
  }
  @media (max-width: 600px) {
    justify-content: start;
  }
`

export const TBody = styled.tr`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, auto);
`

export const Tr = styled.tr`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-areas: 'col1 col2 col3';
  border: solid 1px var(--blue-850);
  border-top: none;
  border-bottom: none;
  &:nth-child(odd) {
    border: solid 1px var(--blue-850);
  }
  &:nth-child(even) {
    background-color: var(--grey-150);
  }
  & td:nth-child(even) {
    border-left: solid 1px var(--blue-850);
    border-right: solid 1px var(--blue-850);
  }

  @media (max-width: 900px) {
    grid-template-columns: 140px 1fr 1fr;
  }
`

export const Td = styled.td<IConteudoTabelaProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-area: ${(props) => props.gridArea};
  padding: 8px;
  & p {
    line-height: normal;
    font-size: 16px;
    ${(props) => props.isSubtitle && 'font-weight: 600;'}
    @media (max-width: 900px) {
      font-size: 14px;
    }
    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
  & span {
    margin-top: 16px;
    font-size: 16px;
    @media (max-width: 900px) {
      font-size: 14px;
    }
    @media (max-width: 400px) {
      font-size: 12px;
      margin-top: 8px;
    }
  }
  @media (max-width: 400px) {
    padding: 4px;
  }
`
export const Lista = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  gap: 8px;
  @media (max-width: 400px) {
    padding-left: 18px;
    gap: 0px;
  }
`
