import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface ICloudDownloadSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function CloudDownloadSVG({
  color = 'var(--white)',
  width = 24,
  height = 24,
  ...props
}: ICloudDownloadSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.1665V13.1665M12 22.1665L9.50002 20.1665M12 22.1665L14.5 20.1665M5.03402 9.28351C4.08823 9.5215 3.2619 10.0967 2.71028 10.9009C2.15865 11.7052 1.9197 12.6833 2.03833 13.6513C2.15695 14.6193 2.62499 15.5107 3.35449 16.158C4.08399 16.8053 5.02475 17.1639 6.00002 17.1665H7.00002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.83 7.30404C15.4882 5.95061 14.6446 4.77778 13.4702 4.0233C12.2957 3.26881 10.8783 2.98913 9.50529 3.24094C8.13228 3.49276 6.90638 4.25722 6.0761 5.37938C5.24582 6.50153 4.8733 7.89741 5.03404 9.28404C5.03404 9.28404 5.18704 10.166 5.50004 10.666"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 17.1663C17.706 17.1658 18.404 17.0158 19.0479 16.7262C19.6917 16.4365 20.267 16.0138 20.7357 15.4858C21.2045 14.9578 21.5561 14.3365 21.7674 13.6628C21.9787 12.9892 22.045 12.2784 21.9618 11.5773C21.8786 10.8761 21.6479 10.2006 21.2848 9.59506C20.9217 8.98953 20.4345 8.46777 19.8552 8.06411C19.276 7.66044 18.6178 7.38404 17.924 7.25308C17.2302 7.12212 16.5166 7.13959 15.83 7.30432L14.5 7.66632"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
