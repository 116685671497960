import React, { FormEvent, useState } from 'react'
import { Button } from '../Button'
import InputText from '../InputText'
import {
  ContainerFaleConosco,
  ContainerInputs,
  AreaForm,
  Form,
  TextArea,
  TextAreaContainer,
  TextFaleConosco,
  Titulo,
  InputNomeArea,
  InputEmailArea,
  InputTelefoneArea,
  InputCNPJArea,
  BtnEnviarArea,
} from './styles'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface IForm {
  nome: string
  email: string
  telefone: string
  cnpj: string
  mensagem: string
}

const Formulario = () => {
  const [errors, setErrors] = useState({
    nome: false,
    email: false,
    telefone: false,
    cnpj: false,
  })
  const [form, setForm] = useState<IForm>({} as IForm)
  const [disable, setDisable] = useState<boolean>(false)
  const handleChange = (
    input:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { name, value } = input.target

    setForm({
      ...form,
      [name]: value,
    })

    setErrors({
      ...errors,
      [name]: false,
    })
  }

  const handleSubmit = async (e: FormEvent) => {
    await setDisable(true)

    e.preventDefault()

    let hasError = false
    const newErrors = {
      ...errors,
    }

    if (form.nome === undefined || form.nome === '') {
      hasError = true
      newErrors.nome = true
    }
    if (form.email === undefined || form.email === '') {
      hasError = true
      newErrors.email = true
    }
    if (form.telefone === undefined || form.telefone === '') {
      hasError = true
      newErrors.telefone = true
    }

    if (hasError) {
      setDisable(false)
      return setErrors(newErrors)
    }

    await axios
      .post('https://www.sistemacicom.com.br/solid/api_admcicom/speak', form)
      .then((res) => {
        if (res.status === 201) {
          toast.success('Mensagem enviada com sucesso!', {
            position: 'top-right',
            toastId: 'customId',
          })
          setForm({
            nome: '',
            email: '',
            telefone: '',
            cnpj: '',
            mensagem: '',
          })
          setDisable(false)
        } else {
          toast.error(
            'Não foi possivel enviar a mensagem, tente novamente mais tarde!',
            {
              position: 'top-right',
              toastId: 'customId',
            },
          )
          setDisable(false)
        }
      })
      .catch(() => {
        toast.error(
          'Não foi possivel enviar a mensagem, tente novamente mais tarde!',
          {
            position: 'top-right',
            toastId: 'customId',
          },
        )
        setDisable(false)
      })
  }
  return (
    <ContainerFaleConosco>
      <ToastContainer />
      <ContainerInputs>
        <Titulo>Fale Conosco</Titulo>
        <TextFaleConosco>
          Envie sua mensagem e em breve entraremos em contato com você.
        </TextFaleConosco>
        <AreaForm>
          <Form onSubmit={handleSubmit} id="formFaleConosco">
            <InputNomeArea>
              <InputText
                type="text"
                label="Nome"
                placeholder="Digite seu nome"
                handleChange={handleChange}
                name="nome"
                value={form.nome}
                error={errors.nome}
              />
            </InputNomeArea>
            <InputEmailArea>
              <InputText
                type="email"
                label="Email"
                placeholder="Digite seu Email"
                handleChange={handleChange}
                name="email"
                value={form.email}
                error={errors.email}
              />
            </InputEmailArea>
            <InputTelefoneArea>
              <InputText
                mask="(99) 99999-9999"
                type="tel"
                label="Telefone/Whatsapp"
                placeholder="Digite seu número"
                handleChange={handleChange}
                name="telefone"
                value={form.telefone}
                error={errors.telefone}
              />
            </InputTelefoneArea>
            <InputCNPJArea>
              <InputText
                mask="99.999.999/9999-99"
                type="text"
                label="CNPJ (Opcional)"
                placeholder="Digite seu CNPJ"
                handleChange={handleChange}
                name="cnpj"
                value={form.cnpj}
                error={errors.cnpj}
              />
            </InputCNPJArea>
            <TextAreaContainer>
              Mensagem (Opcional)
              <TextArea
                placeholder="Escreva sua mensagem"
                name="mensagem"
                value={form.mensagem}
                onChange={handleChange}
              />
            </TextAreaContainer>

            <BtnEnviarArea>
              <Button
                buttonKind="submit"
                buttonSize="large"
                caption="Enviar"
                disabled={disable}
              />
            </BtnEnviarArea>
          </Form>
        </AreaForm>
      </ContainerInputs>
    </ContainerFaleConosco>
  )
}

export default Formulario
