import {
  ContainerContatos,
  AreaContatos,
  Titulo,
  TopDepartamento,
  TextContato,
  Comercial,
  Suporte,
  Qualidade,
  ContainerHorario,
  AsteriskArea,
  TelContato,
  Recepcao,
  TituloDepartamento,
} from './styles'

import { IoRibbon } from 'react-icons/io5'
import { InfoSVG } from '../Svg/infoSVG'
import React, { useState } from 'react'
import { HeadsetSVG } from '../Svg/headsetSVG'
import { HandShakeSVG } from '../Svg/handShakeSVG'
import { WhatsappIconSVG } from '../Svg/WhatsappIconSVG'
import { PhoneSVG } from '../Svg/phoneSVG'

import { ArrobaSVG } from '../Svg/arrobaSVG'
import { MessagesSVG } from '../Svg/messagesSVG'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gtag: any
  }
}

const Contatos = () => {
  const [showComercial, setshowComercial] = useState<boolean>(false)
  const [showSuporte, setshowSuporte] = useState<boolean>(false)

  function showAsterisk(e: React.MouseEvent<HTMLElement>) {
    if (e.currentTarget.id === 'AsteriskComercial') {
      setshowComercial(!showComercial)
    }
    if (e.currentTarget.id === 'AsteriskSuporte') {
      setshowSuporte(!showSuporte)
    }
  }

  function handleWhatsAppComercialClick() {
    window.gtag('event', 'conversion', {
      send_to: 'AW-992531803/ZHjRCK75nYcDENuqo9kD',
      // Additional parameters as needed
    })

    window.open('https://wa.me/message/VZM7F3Z7F2LTK1', '_blank')
  }

  return (
    <>
      <ContainerContatos>
        <Titulo>Contatos</Titulo>
        <AreaContatos>
          {/* comercial */}
          <Comercial>
            <TopDepartamento className="comercial">
              <HandShakeSVG color="var(--yellow-200)" />
              <TituloDepartamento>Comercial</TituloDepartamento>
            </TopDepartamento>
            <TelContato>
              <PhoneSVG />
              (11) 3042-5369
              <AsteriskArea
                id="AsteriskComercial"
                onClick={(e: React.MouseEvent<HTMLElement>) => showAsterisk(e)}
              >
                <InfoSVG />
              </AsteriskArea>
            </TelContato>
            <TextContato
              className={showComercial ? 'opcaoTel show' : 'opcaoTel'}
            >
              *Disque “4” ao ser atendido.
            </TextContato>
            <TextContato>
              <WhatsappIconSVG width={32} height={32} color={'var(--white)'} />
              <a
                className="comercial"
                href="#"
                onClick={handleWhatsAppComercialClick}
                rel="noreferrer"
              >
                (11) 98232-3059
              </a>
            </TextContato>
            <TextContato className="email">
              <ArrobaSVG /> comercial@cicom.com.br
            </TextContato>
          </Comercial>
          {/* recepcao */}
          <Recepcao>
            <TopDepartamento>
              <MessagesSVG color="var(--yellow-200)" />
              <TituloDepartamento>Recepção</TituloDepartamento>
            </TopDepartamento>
            <TelContato>
              <PhoneSVG />
              (11) 3042-5369
            </TelContato>
            <TextContato>
              <WhatsappIconSVG width={32} height={32} color={'var(--white)'} />
              <a
                href="https://wa.me/5511978295412"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                (11) 97829-5412
              </a>
            </TextContato>
          </Recepcao>
          {/* suporte */}
          <Suporte>
            <TopDepartamento>
              {/* <img src={headSetIcon} alt="Icone Suporte" /> */}
              <HeadsetSVG color="var(--yellow-200)" />
              <TituloDepartamento>Suporte</TituloDepartamento>
            </TopDepartamento>
            <TelContato>
              <PhoneSVG />
              (11) 3042-5369
              <AsteriskArea
                id="AsteriskSuporte"
                onClick={(e: React.MouseEvent<HTMLElement>) => showAsterisk(e)}
              >
                <InfoSVG />
              </AsteriskArea>
            </TelContato>
            <TextContato className={showSuporte ? 'opcaoTel show' : 'opcaoTel'}>
              *Disque “3” ao ser atendido.
            </TextContato>
          </Suporte>

          {/* qualidade */}
          <Qualidade>
            <TopDepartamento>
              <IoRibbon size={34} />
              <TituloDepartamento>Controle de Qualidade</TituloDepartamento>
            </TopDepartamento>
            <TextContato className="info">
              Para elogios e/ou reclamações.
            </TextContato>
            <TextContato>
              <WhatsappIconSVG width={32} height={32} color={'var(--white)'} />
              <a
                href="https://wa.me/5511988988088"
                target="_blank"
                rel="noreferrer"
              >
                (11) 98898-8088
              </a>
            </TextContato>
          </Qualidade>
        </AreaContatos>
        <ContainerHorario>
          *Atendemos de segunda a sexta, das 8h30 às 17h30.
        </ContainerHorario>
      </ContainerContatos>
    </>
  )
}

export default Contatos
