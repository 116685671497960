import React from 'react'
import {
  AreaBotao,
  AreaImagem,
  Conteudo,
  GridSobreCicom,
  Imagem,
  Texto,
  Titulo,
} from './styles'
import desenvolvedor from '../../assets/imgs/desenvolvedor.png'
import { Button } from '../Button'
import { CicomApoia } from '../CicomApoia'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'

function SobreCicom() {
  return (
    <Conteudo id="SobreCicom">
      <GridSobreCicom>
        <Titulo>Sobre a CICOM</Titulo>
        <Texto>
          Somos uma empresa moderna e inovadora, atuando no desenvolvimento de
          sistemas para o setor automotivo, desde o ano 2000. Sempre
          acompanhamos as novas tecnologias, para proporcionarmos mais segurança
          e qualidade aos nossos clientes. Temos uma equipe de profissionais
          preparada para atender nossos clientes da melhor maneira possível.
        </Texto>
        <AreaBotao>
          <Link className="link-botao" to="/empresa">
            <Button
              buttonType="primary"
              caption="Veja mais sobre a nossa empresa"
              buttonSize="large"
            />
          </Link>
        </AreaBotao>
        <AreaImagem>
          <LazyLoad height={404} once>
            <Imagem src={desenvolvedor} />
          </LazyLoad>
        </AreaImagem>
      </GridSobreCicom>
      <CicomApoia />
    </Conteudo>
  )
}

export { SobreCicom }
