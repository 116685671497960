import styled from 'styled-components'

interface Props {
  aparecerMenu: Boolean
}

export const NavMenu = styled.nav`
  width: 100%;
  height: 100%;
  margin-left: 3%;
  @media (max-width: 1150px) {
    margin-left: 2%;
  }
`

export const Ul = styled.ul<Props>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    margin-top: 32px;
    display: ${(props: Props) => (props.aparecerMenu ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
  }
`

export const Item = styled.li`
  list-style-type: none;
  color: #ffffff;
  font-size: 20px;
  @media (max-width: 1000px) {
    margin-bottom: 12px;
  }
`
export const BigItem = styled.li`
  list-style-type: none;
  color: #ffffff;
  font-size: 20px;
  @media (max-width: 1150px) {
    width: 85px;
    text-align: center;
  }
  @media (max-width: 1000px) {
    margin-bottom: 12px;
    width: auto;
    text-align: start;
  }
`
export const DivBtn = styled.a`
  text-decoration: none;
  width: 195px;
  @media (max-width: 300px) {
    width: 100%;
  }
`
