import React from 'react'
import topNossaEmpresa from '../../assets/imgs/topNossaEmpresa.png'
import {
  AreaImagemTopEmpresa,
  AreaTextoTopEmpresa,
  DivCardTopEmpresa,
  Destaque,
  AreaCardsTopEmpresa,
  DivSubtituloTopEmpresa,
  DivTituloTopEmpresa,
  GridTopEmpresa,
  IconeCard,
  ImagemTopEmpresa,
  SubtituloTopEmpresa,
  TituloCard,
  TituloTopEmpresa,
  ConteudoTopEmpresa,
} from './styles'
import { HeartSVG } from '../Svg/heartSVG'
import { GraduationCapSVG } from '../Svg/graduationCapSVG'

function TopNossaEmpresa() {
  return (
    <ConteudoTopEmpresa id="TopEmpresa">
      <GridTopEmpresa>
        <AreaImagemTopEmpresa>
          <ImagemTopEmpresa src={topNossaEmpresa} />
        </AreaImagemTopEmpresa>
        <AreaTextoTopEmpresa>
          <DivTituloTopEmpresa>
            <TituloTopEmpresa>
              Somos especialistas em
              <Destaque> desenvolvimento de sistemas</Destaque>
            </TituloTopEmpresa>
          </DivTituloTopEmpresa>
          <DivSubtituloTopEmpresa>
            <SubtituloTopEmpresa>
              A CICOM atua no desenvolvimento de softwares para o setor
              automotivo desde o ano 2000.
            </SubtituloTopEmpresa>
          </DivSubtituloTopEmpresa>
          <AreaCardsTopEmpresa>
            <DivCardTopEmpresa>
              <IconeCard>
                <HeartSVG />
              </IconeCard>
              <DivTituloTopEmpresa>
                <TituloCard>Atendimento humanizado </TituloCard>
              </DivTituloTopEmpresa>
            </DivCardTopEmpresa>
            <DivCardTopEmpresa>
              <IconeCard>
                <GraduationCapSVG />
              </IconeCard>
              <DivTituloTopEmpresa>
                <TituloCard>
                  Equipe de profissionais altamente qualificada e preparada
                </TituloCard>
              </DivTituloTopEmpresa>
            </DivCardTopEmpresa>
          </AreaCardsTopEmpresa>
        </AreaTextoTopEmpresa>
      </GridTopEmpresa>
    </ConteudoTopEmpresa>
  )
}

export { TopNossaEmpresa }
