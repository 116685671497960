import styled from 'styled-components'

export const Conteudo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  background-color: var(--white);
  @media (max-width: 1100px) {
    background-color: var(--grey-100);
  }
  @media (max-width: 600px) {
    padding-top: 24px;
  }
`
export const AreaTexto = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 924px) {
    width: 770px;
  }
  @media (max-width: 800px) {
    width: 570px;
  }
  @media (max-width: 600px) {
    width: 380px;
  }
  @media (max-width: 400px) {
    width: 328px;
  }
  @media (max-width: 350px) {
    width: 260px;
  }
`
export const AreaTitulo = styled.div`
  width: 100%;
  text-align: center;
`
export const Titulo = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 43.2px;
  color: var(--black);
  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media (max-width: 400px) {
    font-size: 18px;
    line-height: 25.29px;
  }
`
export const AreaSubtitulo = styled.div`
  margin-top: 8px;
  width: 662px;
  text-align: center;
  @media (max-width: 800px) {
    width: 100%;
  }
`
export const Subtitulo = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: var(--blue-850-90);
  @media (max-width: 400px) {
    font-size: 16px;
    line-height: 16.94px;
  }
`
export const AreaImagem = styled.div`
  margin-top: 40px;
  height: 380px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 32px;
    height: 300px;
  }
  @media (max-width: 600px) {
    margin-top: 24px;
    height: 220px;
  }
  @media (max-width: 350px) {
    margin-top: 16px;
    height: 180px;
  }
`
export const Imagem = styled.img`
  width: 550px;
  height: 500px;
  @media (max-width: 800px) {
    width: 450px;
    height: 400px;
  }
  @media (max-width: 600px) {
    width: 340px;
    height: 290px;
  }
  @media (max-width: 350px) {
    width: 280px;
    height: 230px;
  }
`

// Area dos Cards ======================================================================
export const AreaCards = styled.div`
  position: relative;
  margin-top: 104px;
  margin-bottom: 52px;
  width: 100%;
  height: 281px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    180deg,
    var(--blue-sky) 0%,
    var(--blue-sky-300-0) 100%
  );
  @media (max-width: 1100px) {
    background: var(--grey-100);
    height: auto;
  }
  @media (max-width: 600px) {
    margin-top: 94px;
  }
  @media (max-width: 350px) {
    margin-top: 74px;
  }
`

export const GridCards = styled.div`
  position: absolute;
  top: -48px;
  max-width: 1040px;
  max-height: 380px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 328px);
  grid-template-rows: 378px;
  @media (max-width: 1100px) {
    position: relative;
    max-height: 1480px;
    max-width: 328px;
    grid-template-columns: 328px;
    grid-template-rows: repeat(3, 378px);
  }
  @media (max-width: 350px) {
    max-width: 260px;
    grid-template-columns: 260px;
  }
`
