import styled from 'styled-components'

export const Container = styled.div`
  img {
    border-radius: 7.25px;
    width: 637px;
    height: 385px;

    @media (max-width: 1279px) {
      width: 500px;
      height: 250px;
    }

    @media (max-width: 1024px) {
      width: 637px;
      height: 385px;
    }

    @media (max-width: 670px) {
      width: 328px;
      height: 198px;
    }

    @media (max-width: 360px) {
      width: 300px;
      height: 180px;
    }
    @media (max-width: 320px) {
      width: 250px;
      height: 130px;
    }
  }
`
