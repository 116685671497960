import { ContainerTop, ContainerTextTop, Titulo, Text } from './styles'

const TopModulos = () => {
  return (
    <ContainerTop>
      <ContainerTextTop>
        <Titulo>Módulos do sistema</Titulo>
        <Text>
          Algumas funções são modulares. Ou seja, você contrata somente o que
          realmente precisar. A qualquer momento você poderá remover e/ou
          acrescentar novos módulos em seu plano.
        </Text>
      </ContainerTextTop>
    </ContainerTop>
  )
}

export { TopModulos }
