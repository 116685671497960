import styled from 'styled-components'

export const ConteudoTop = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--grey-100);
`
export const GridTop = styled.div`
  margin: 50px 24px 30px 24px;
  max-height: 480px;
  max-width: 100%;
  display: grid;
  grid-template-columns: 580px 580px;
  grid-template-rows: 360px 110px;
  grid-gap: 32px 100px;
  grid-template-areas:
    'textoTop imagemTop'
    'textoTop imagemTop';

  @media (max-width: 1366px) {
    grid-gap: 32px;
    grid-template-areas:
      'textoTop imagemTop'
      'textoTop imagemTop';
  }
  @media (max-width: 1230px) {
    margin: 32px 12px;
    max-height: 100%;
    max-width: 570px;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-gap: 32px;
    grid-template-areas:
      'imagemTop'
      'textoTop';
  }
  @media (max-width: 600px) {
    margin: 32px 8px;
    max-height: 1100px;
    max-width: 90vw;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-gap: 32px;
    grid-template-areas:
      'imagemTop'
      'textoTop';
  }
  @media (max-width: 400px) {
    max-height: 1100px;
    grid-template-columns: 100%;
  }
`
export const AreaTextoTop = styled.div`
  grid-area: textoTop;
`
export const TextoPrincipalTop = styled.p`
  font-size: 48px;
  font-weight: 800;
  line-height: 72px;
  color: var(--black);
  @media (max-width: 600px) {
    font-size: 42px;
    line-height: 64px;
  }
  @media (max-width: 400px) {
    font-size: 24px;
    line-height: 36px;
  }
`
export const TextoSecundarioTop = styled.p`
  margin-top: 32px;
  font-size: 24px;
  line-height: 28px;
  color: var(--black);
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
`
export const Span = styled.span`
  color: var(--blue-600);
  font-size: 48px;
  font-weight: 800;
  line-height: 72px;
  @media (max-width: 600px) {
    font-size: 42px;
    line-height: 64px;
  }
  @media (max-width: 400px) {
    font-size: 24px;
    line-height: 36px;
  }
`
export const AreaButtonsTop = styled.div`
  margin-top: 68px;
  width: 510px;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  @media (max-width: 1180px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 16px;
  }
`
export const DivButtonTop = styled.a`
  text-decoration: none;
  width: 240px;

  @media (max-width: 600px) {
    width: 100%;
  }
`
export const AreaImagemTop = styled.div`
  grid-area: imagemTop;
  height: auto;
  width: auto;
`
export const ImagemTop = styled.img`
  height: 470px;
  width: 560px;
  @media (max-width: 600px) {
    width: 90vw;
    height: 80vw;
  }
`
