import React from 'react'
import { MiddleDuvidas } from '../components/MiddleDuvidas'
import { TopDuvidas } from '../components/TopDuvidas'

function Duvidas() {
  return (
    <>
      <TopDuvidas />
      <MiddleDuvidas />
    </>
  )
}

export { Duvidas }
