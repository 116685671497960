import React from 'react'

import { TextoResposta } from './styles'

interface IPerguntaERespostaProps {
  resposta: string
}

function Resposta({ resposta }: IPerguntaERespostaProps) {
  return <TextoResposta>{resposta}</TextoResposta>
}

export { Resposta }
