import React, { useEffect, useState } from 'react'
import { UpArrowSVG } from '../Svg/UpArrow'
import { ReturnTop, Span } from './styles'

interface IProps {
  textColor?: string
  top?: string
  left?: string
  responsiveHide?: boolean
  hideInPX?: string
  marginBottom?: string
}

function ReturnToTop({
  textColor,
  top,
  left,
  responsiveHide,
  hideInPX,
  marginBottom,
}: IProps) {
  const [scrollY, setScrollY] = useState<number>(0)
  const [show, setShow] = useState<boolean>(false)
  document.addEventListener('scroll', function () {
    setScrollY(window.scrollY)
  })

  useEffect(() => {
    if (scrollY > 450) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [scrollY])

  function onClickReturnTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <ReturnTop
      show={show}
      top={top}
      left={left}
      responsiveHide={responsiveHide}
      hideInPX={hideInPX}
      onClick={onClickReturnTop}
      marginBottom={marginBottom}
    >
      <UpArrowSVG />
      <Span textColor={textColor}>Voltar ao topo</Span>
    </ReturnTop>
  )
}

export { ReturnToTop }
