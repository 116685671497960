import { useState, useEffect } from 'react'
import {
  Container,
  Table,
  TituloTable,
  BodyTable,
  ContainerTable,
} from './styles'
import axios from 'axios'
import { Loader } from '../Loader'
import { ReturnToTop } from '../ReturnToTop'

interface ICustomers {
  cod_cicom: number
  fantasia: string
  uf: string
  estado: string
  cidade: string
  bairro: string
}
interface ITableClientesProps {
  chooseUF: string
}

const TableClientes = ({ chooseUF = 'todos' }: ITableClientesProps) => {
  const [customers, setCustomers] = useState<ICustomers[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getCustomers = async (chooseUF: string) => {
      await axios
        .get(
          chooseUF === 'todos'
            ? 'https://www.sistemacicom.com.br/solid/api_admcicom/customerlist'
            : `https://www.sistemacicom.com.br/solid/api_admcicom/customerlist/${chooseUF}`,
        )
        .then(({ data }) => {
          setCustomers(data)
        })

      setIsLoading(false)
    }

    getCustomers(chooseUF)
  }, [chooseUF])

  if (isLoading) {
    return (
      <Container>
        <ContainerTable>
          <Loader />
        </ContainerTable>
      </Container>
    )
  } else {
    return (
      <>
        <ReturnToTop responsiveHide={true} hideInPX="1150px" />
        <Container>
          <ContainerTable>
            <Table>
              <TituloTable>
                <tr>
                  <th>Empresa</th>
                  <th>Estado</th>
                  <th>Cidade</th>
                  <th>Bairro</th>
                </tr>
              </TituloTable>
              {!isLoading && (
                <BodyTable>
                  {customers.map((client) => (
                    <tr key={client.cod_cicom}>
                      <td>{client.fantasia.toUpperCase()}</td>
                      <td>{`${
                        client.uf.toUpperCase() +
                        ' - ' +
                        client.estado.toUpperCase()
                      }`}</td>
                      <td>{client.cidade.toUpperCase()}</td>
                      <td>{client.bairro.toUpperCase()}</td>
                    </tr>
                  ))}
                </BodyTable>
              )}
            </Table>
          </ContainerTable>
        </Container>
      </>
    )
  }
}

export { TableClientes }
