export const ufs = [
  { value: 'RO', label: 'RO-Rondônia' },
  { value: 'AC', label: 'AC-Acre' },
  { value: 'AM', label: 'AM-Amazonas' },
  { value: 'RR', label: 'RR-Roraima' },
  { value: 'PA', label: 'PA-Pará' },
  { value: 'AP', label: 'AP-Amapá' },
  { value: 'TO', label: 'TO-Tocantins' },
  { value: 'MA', label: 'MA-Maranhão' },
  { value: 'PI', label: 'PI-Piauí' },
  { value: 'CE', label: 'CE-Ceará' },
  { value: 'RN', label: 'RN-Rio Grande do Norte' },
  { value: 'PB', label: 'PB-Paraíba' },
  { value: 'PE', label: 'PE-Pernambuco' },
  { value: 'AL', label: 'AL-Alagoas' },
  { value: 'SE', label: 'SE-Sergipe' },
  { value: 'BA', label: 'BA-Bahia' },
  { value: 'MG', label: 'MG-Minas Gerais' },
  { value: 'ES', label: 'ES-Espírito Santo' },
  { value: 'RJ', label: 'RJ-Rio de Janeiro' },
  { value: 'SP', label: 'SP-São Paulo' },
  { value: 'PR', label: 'PR-Paraná' },
  { value: 'SC', label: 'SC-Santa Catarina' },
  { value: 'RS', label: 'RS-Rio Grande do Sul' },
  { value: 'MS', label: 'MS-Mato Grosso do Sul' },
  { value: 'MT', label: 'MT-Mato Grosso' },
  { value: 'GO', label: 'GO-Goiás' },
  { value: 'DF', label: 'DF-Distrito Federal' },
]
