import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IDiamanteSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function DiamanteSVG({
  color = '#FFFFFF',
  width = 22,
  height = 22,
}: IDiamanteSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.06244 7.5625H19.9374M1.52189 8.08715L10.4486 19.6655C10.5135 19.7501 10.597 19.8186 10.6926 19.8657C10.7882 19.9129 10.8933 19.9374 10.9999 19.9374C11.1065 19.9374 11.2117 19.9129 11.3073 19.8657C11.4029 19.8186 11.4863 19.7501 11.5512 19.6655L20.478 8.08715C20.5658 7.97287 20.6167 7.83459 20.624 7.69068C20.6313 7.54677 20.5946 7.40405 20.5188 7.28148L17.4908 2.39379C17.4285 2.29264 17.3413 2.20911 17.2376 2.15112C17.1339 2.09314 17.0171 2.06263 16.8983 2.0625H5.10162C4.9828 2.06263 4.86599 2.09314 4.76228 2.15112C4.65858 2.20911 4.57142 2.29264 4.50908 2.39379L1.48107 7.28148C1.4053 7.40405 1.36862 7.54677 1.37591 7.69068C1.3832 7.83459 1.43412 7.97287 1.52189 8.08715V8.08715Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1875 2.75L15.125 7.5625L11 2.0625M4.8125 2.75L6.875 7.5625L11 2.0625M11 19.25L6.875 7.5625M11 19.25L15.125 7.5625"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
