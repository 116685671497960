import styled from 'styled-components'

export const Conteudo = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
  background-color: var(--grey-100);
`

export const GridSobreCicom = styled.div`
  display: grid;
  max-width: 950px;
  max-height: 410px;
  grid-template-columns: 433px 433px;
  grid-template-rows: 84px auto auto;
  grid-template-areas:
    'titulo imagem'
    'texto imagem'
    'botao imagem';
  grid-gap: 24px 40px;

  @media (max-width: 1050px) {
    max-height: 910px;
    grid-template-columns: 433px;
    grid-template-rows: auto auto;
    grid-template-areas:
      'titulo'
      'texto'
      'imagem'
      'botao';
    grid-gap: 16px;
  }
  @media (max-width: 500px) {
    max-width: 340px;
    max-height: 910px;
    grid-template-columns: 328px;
    grid-template-rows: auto auto;
    grid-template-areas:
      'titulo'
      'texto'
      'imagem'
      'botao';
    grid-gap: 16px;
  }
  @media (max-width: 350px) {
    max-width: 250px;
    max-height: 910px;
    grid-template-columns: 250px;
    grid-template-rows: auto auto;
    grid-template-areas:
      'titulo'
      'texto'
      'imagem'
      'botao';
    grid-gap: 16px;
  }
`

export const Titulo = styled.p`
  margin-top: 55px;
  height: 29px;
  grid-area: titulo;
  color: var(--black);
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  @media (max-width: 500px) {
    font-size: 18px;
    line-height: 21.6px;
  }
`
export const Texto = styled.p`
  grid-area: texto;
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 16.8px;
    margin-bottom: 24px;
  }
  @media (max-width: 350px) {
    font-size: 12px;
    line-height: 14.8px;
    margin-bottom: 24px;
  }
`
export const AreaBotao = styled.div`
  grid-area: botao;
  width: 100%;
  height: 56px;
  margin-top: 16px;
  @media (max-width: 1050px) {
    margin-top: -12px;
  }
`

// Area da imagem =============================================================
export const AreaImagem = styled.div`
  grid-area: imagem;
  width: auto;
  height: auto;
`
export const Imagem = styled.img`
  width: 100%;
  height: 404px;
  @media (max-width: 500px) {
    width: 328px;
    height: 268px;
  }
  @media (max-width: 350px) {
    width: 250px;
    height: 200px;
  }
`
