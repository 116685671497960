import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IMercadoLivreIconSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function MercadoLivreIconSVG({
  color = 'var(--blue-800)',
  width = 48,
  height = 48,
}: IMercadoLivreIconSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 36.978c10.77 0 19.5-5.81 19.5-12.978S34.77 11.022 24 11.022 4.5 16.832 4.5 24 13.23 36.978 24 36.978z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.704 15.53c2.015.96 4.171 1.59 6.386 1.866a22.82 22.82 0 004.546-.773"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.883 15.614a8.616 8.616 0 01-5.166 1.485c-3.334 0-6.224-2.199-9.214-2.199-2.669 0-7.19 4.373-7.19 5.164 0 .791 1.31 1.26 2.373.74.62-.303 3.31-2.914 5.483-2.914 2.174 0 9.22 7.136 9.857 7.806.99 1.038-.926 3.274-2.149 2.05-1.223-1.224-3.41-3.162-3.41-3.162"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.4 22.683a24 24 0 00-8.547 2.692m-2.273 2.081c.989 1.037-.926 3.273-2.15 2.05-1.222-1.223-2.58-2.513-2.58-2.513"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.135 29.215c.988 1.037-.927 3.273-2.15 2.05a76.464 76.464 0 00-2.025-1.962M24.201 31.316a2.31 2.31 0 003.65-.186"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2 31.316c.532-.697.49-3.182-2.242-2.688.642-1.219.066-3.146-2.388-2.01a1.69 1.69 0 00-3.146-.658 1.454 1.454 0 00-2.8-.28c-.544 1.103.296 3.096 2.092 1.976-.182 1.944.84 2.537 2.684 1.78.099 1.91 1.367 1.745 2.273 1.3a1.938 1.938 0 003.529.58H24.2zM4.67 22.279a18.308 18.308 0 019.064 3.214"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
