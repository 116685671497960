import { SelectUF } from '../components/SelectUF'
import { TableClientes } from '../components/TableClientes'
import { TopClientes } from '../components/TopClientes'
import { useState } from 'react'

const NossosClientes = () => {
  const [chooseUF, setChooseUF] = useState('')

  const handleFilterUF = (uf: string) => {
    setChooseUF(uf)
  }
  return (
    <>
      <TopClientes>
        <SelectUF handleFilterUF={handleFilterUF} />
      </TopClientes>
      <TableClientes chooseUF={chooseUF} />
    </>
  )
}

export { NossosClientes }
