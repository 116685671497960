import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IBackupNuvemIconSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function BackupNuvemIconSVG({
  color = 'var(--white)',
  width = 40,
  height = 40,
}: IBackupNuvemIconSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-4 -6 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.812 2.684C10.811 0.96027 13.3605 0.00827703 16 0C21.38 0 25.846 4 26.332 9.158C29.516 9.608 32 12.274 32 15.546C32 19.138 29.004 22 25.374 22H20C19.7348 22 19.4804 21.8946 19.2929 21.7071C19.1054 21.5196 19 21.2652 19 21C19 20.7348 19.1054 20.4804 19.2929 20.2929C19.4804 20.1054 19.7348 20 20 20H25.376C27.958 20 30 17.976 30 15.546C30 13.114 27.96 11.09 25.374 11.09H24.374V10.09C24.376 5.65 20.656 2 16 2C13.8398 2.00863 11.7537 2.78887 10.118 4.2C8.604 5.504 7.812 7.076 7.812 8.31V9.206L6.922 9.304C4.128 9.61 2 11.904 2 14.636C2 17.57 4.46 20 7.562 20H12C12.2652 20 12.5196 20.1054 12.7071 20.2929C12.8946 20.4804 13 20.7348 13 21C13 21.2652 12.8946 21.5196 12.7071 21.7071C12.5196 21.8946 12.2652 22 12 22H7.562C3.416 22 0 18.732 0 14.636C0 11.11 2.532 8.19 5.884 7.45C6.17 5.724 7.28 4.004 8.812 2.684Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.292 8.29196C15.3849 8.19883 15.4952 8.12494 15.6167 8.07453C15.7382 8.02412 15.8685 7.99817 16 7.99817C16.1315 7.99817 16.2618 8.02412 16.3833 8.07453C16.5048 8.12494 16.6151 8.19883 16.708 8.29196L22.708 14.292C22.8958 14.4797 23.0013 14.7344 23.0013 15C23.0013 15.2655 22.8958 15.5202 22.708 15.708C22.5202 15.8957 22.2656 16.0012 22 16.0012C21.7344 16.0012 21.4798 15.8957 21.292 15.708L17 11.414V29C17 29.2652 16.8946 29.5195 16.7071 29.7071C16.5196 29.8946 16.2652 30 16 30C15.7348 30 15.4804 29.8946 15.2929 29.7071C15.1054 29.5195 15 29.2652 15 29V11.414L10.708 15.708C10.5202 15.8957 10.2656 16.0012 10 16.0012C9.73445 16.0012 9.47977 15.8957 9.292 15.708C9.10423 15.5202 8.99874 15.2655 8.99874 15C8.99874 14.7344 9.10423 14.4797 9.292 14.292L15.292 8.29196Z"
        fill={color}
      />
    </svg>
  )
}
