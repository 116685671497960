import styled from 'styled-components'

export const ContainerContatos = styled.div`
  width: 100%;
  background: var(--blue-800);
  background: linear-gradient(180deg, var(--blue-800) 0%, var(--blue-850) 100%);
  color: var(--white);
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`
export const Titulo = styled.div`
  font-weight: 600;
  font-size: 32px;
  color: var(--yellow-300);
`

export const AreaContatos = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 300px);
  grid-template-rows: auto;
  grid-template-areas: 'recepcao comercial suporte qualidade';
  grid-gap: 48px 40px;
  @media (max-width: 1400px) {
    grid-template-columns: 300px 300px;
    grid-template-rows: auto;
    grid-template-areas: 'recepcao comercial' 'suporte qualidade';
  }

  @media (max-width: 980px) {
    max-width: 300px;
    grid-template-columns: 300px;
    grid-template-areas: 'recepcao' 'comercial' 'suporte' 'qualidade';
  }

  @media (max-width: 370px) {
    max-width: 290px;
    grid-template-columns: 280px;
    grid-template-areas: 'recepcao' 'comercial' 'suporte' 'qualidade';
  }
  @media (max-width: 320px) {
    max-width: 100%;
    grid-template-columns: 100%;
    grid-template-areas: 'recepcao' 'comercial' 'suporte' 'qualidade';
    padding: 0px 12px;
  }

  .opcaoTel {
    display: none;
  }
  .opcaoTel.show {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    color: var(--grey-500);
  }
`

export const Comercial = styled.div`
  grid-area: comercial;
  width: 100%;
`

export const Suporte = styled.div`
  grid-area: suporte;
  width: 100%;
`
export const Recepcao = styled.div`
  grid-area: recepcao;
  width: 100%;
`

export const Qualidade = styled.div`
  grid-area: qualidade;
  width: 100%;
  .info {
    margin-top: 16px;
    font-size: 18px;

    @media (max-width: 800px) {
      width: auto;
    }
    @media (max-width: 370px) {
      font-size: 14px;
    }
  }

  span:last-child {
    margin-top: 24px;
  }
`

export const TopDepartamento = styled.div`
  font-weight: 800;
  font-size: 20px;
  height: 32px;
  display: flex;
  flex: none;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
  letter-spacing: 2px;
  white-space: nowrap;
  color: #ffb31a;
  img {
    width: 32px;
    height: 32px;
  }
  &.comercial {
    margin-left: -2px;
    gap: 8px;
  }
  @media (max-width: 400px) {
    letter-spacing: 1px;
  }
  @media (max-width: 315px) {
    white-space: normal;
    letter-spacing: 0px;
  }
`

export const TituloDepartamento = styled.div`
  /* border-bottom: 3px solid var(--yellow-300); */
`

export const TelContato = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 16px;
  margin-left: -6px;
  font-size: 18px;
`

export const TextContato = styled.span`
  display: block;
  font-size: 18px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 6px;

  @media (max-width: 370px) {
    font-size: 14px;
  }
  & > a.comercial {
    border-bottom: solid 1px var(--white);
  }
  a {
    text-decoration: none;
    color: var(--white);
    @media (max-width: 980px) {
      border-bottom: solid 1px var(--white);
    }
  }
  a:hover {
    cursor: pointer;
    text-decoration: none;
    border-bottom: solid 2px var(--white);
    color: var(--white);
  }
  &.chat {
    margin-left: -4px;
  }
  &.chat > a:hover {
    border-bottom: none;
  }
  &.chat > svg {
    margin-right: -2px;
  }
  &.email {
    margin-left: -4px;
  }
`

export const AsteriskArea = styled.div`
  cursor: pointer;
  user-select: none;
  width: 24px;
  height: 24px;
  border-radius: 128px;
  transition: 0.3s;
  &:hover {
    box-shadow: inset 0px 0px 4px 1px var(--white);
  }
`

export const ContainerHorario = styled.div`
  text-align: center;
  padding: 0px 12px;
  font-size: 18px;

  @media (max-width: 370px) {
    font-size: 14px;
  }
  @media (max-width: 320px) {
    width: 100%;
    padding-right: 8px;
  }
`
