import styled from 'styled-components'

//
export const Container = styled.footer`
  display: flex;
  width: 100%;
  padding: 20px 95px 20px 95px;
  background: linear-gradient(0deg, var(--blue-900) 0%, var(--blue-850) 100%);
  font-size: 14px;
  @media (max-width: 1050px) {
    padding: 20px 40px 20px 40px;
  }
  @media (max-width: 930px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 40px 0px 20px 0px;
  }

  span {
    color: var(--white);
    display: block;
    text-align: center;
  }
`
export const AreaTexto = styled.div`
  width: auto;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  gap: 12px;
  span {
    line-height: 150%;
  }
  @media (max-width: 930px) {
    order: 3;
  }
  @media (max-width: 450px) {
    padding: 0px 12px;
    width: 100%;
  }
`
export const Contato = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 16px;
  @media (max-width: 450px) {
    width: 280px;
    flex-wrap: wrap;

    .tracoLocalizacao {
      display: none;
    }
  }
  @media (max-width: 320px) {
    width: auto;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    gap: 2px;

    .tracoLocalizacao {
      display: block;
    }
  }
`

export const Comercial = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
  & svg {
    align-self: center;
    margin-bottom: 2px;
  }
`
export const A = styled.a`
  text-decoration: none;
  color: var(--white);
  text-align: center;
`
export const Localização = styled.span`
  display: block;
`

export const ReturnTop = styled.div`
  align-self: end;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  img {
    width: 52px;
    height: 52px;
  }
  @media (max-width: 930px) {
    order: 1;
    align-self: center;
  }
`
export const Logo = styled.img`
  width: 65px;
  height: 60px;
  margin-bottom: -4px;
`
