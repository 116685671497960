import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IDownloadSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function DownloadSVG({
  color = 'var(--black)',
  width = 16,
  height = 16,
  ...props
}: IDownloadSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.666 6H9.999V2H6v4H3.333l4.666 5.333L12.666 6zm-10 6.667h10.667V14H2.666v-1.333z"
        fill={color}
      />
    </svg>
  )
}
