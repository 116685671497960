import styled from 'styled-components'

export const Conteudo = styled.div`
  position: relative;

  width: 100%;
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background-color: var(--blue-900);
  border-radius: 5px;
`

export const LinhasVerticais = styled.img`
  position: absolute;
  height: 672px;
  width: 210px;
  left: -1px;
  top: 0px;
  color: var(--white);
`
export const LinhasDiagonais = styled.img`
  position: absolute;
  height: 495px;
  width: 47px;
  right: 16px;
  bottom: 50px;
  color: var(--white);
  @media (max-width: 400px) {
    right: 0px;
    bottom: 0px;
  }
`
// Areas =================================================
export const AreaTexto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (max-width: 1100px) {
    padding: 0px 16px;
  }
  @media (max-width: 600px) {
    padding: 0px;
    width: 380px;
  }
  @media (max-width: 400px) {
    width: 328px;
  }
  @media (max-width: 350px) {
    width: 260px;
  }
`
export const AreaDuvidas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 984px;
  @media (max-width: 1350px) {
    width: 750px;
  }
  @media (max-width: 880px) {
    width: 550px;
  }
  @media (max-width: 600px) {
    width: 380px;
  }
  @media (max-width: 400px) {
    width: 328px;
  }
  @media (max-width: 350px) {
    width: 260px;
  }
`
export const AreaSaibaMais = styled.div`
  width: auto;
  border-bottom: solid 2px var(--white);
`
// Texto =================================================
export const Titulo = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: var(--white);

  @media (max-width: 400px) {
    font-size: 20px;
    line-height: 24.2px;
  }
`
export const TextoPergunta = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--black);
`
export const TextoResposta = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: var(--black);
  white-space: pre-wrap;
  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 16.8px;
  }
`
export const SaibaMaisTemp = styled.p`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
`
// accordion perguntas e respostas =======================
export const AreaPergunta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  background-color: var(--grey-100);
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
`
export const AreaResposta = styled.div`
  height: auto;
  overflow: hidden;
  width: 100%;
  padding: 24px;
  background-color: var(--grey-100);
  border-radius: 0px 0px 5px 5px;
`
export const Wrap = styled.div`
  height: auto;
  overflow: hidden;
  width: 100%;
  margin-top: -24px;
`
