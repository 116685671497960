import React from 'react'
import { Perguntas } from '../Perguntas'
import { IconeAreaClienteSVG } from '../Svg/iconeAreaCliente'
import { IconeGRSVG } from '../Svg/iconeGRSVG'
import { IconePaPSVG } from '../Svg/iconePaPSVG'
import { IconeSolucoesSVG } from '../Svg/iconeSolucoesSVG'
import { OpcoesAjuda } from '../OpcaoAjuda'
import { AreaOpcoesAjuda, Conteudo } from './styles'

function MiddleDuvidas() {
  return (
    <Conteudo>
      <AreaOpcoesAjuda>
        <OpcoesAjuda
          titulo="Manual passo a passo"
          descricao="Instruções de como realizar funções no sistema."
          icone={<IconePaPSVG width={36} height={36} />}
          link="https://www.sistemacicom.com.br/pap"
        />
        <OpcoesAjuda
          titulo="Guia de referências"
          descricao="Explicações sobre o sistema."
          icone={<IconeGRSVG width={40} height={40} />}
          link="https://www.sistemacicom.com.br/gr"
        />
        <OpcoesAjuda
          titulo="Soluções gerais"
          descricao="Soluções relacionadas a algumas mensagens que podem aparecer no sistema."
          icone={<IconeSolucoesSVG width={44} height={44} />}
          link="https://www.melion.com.br/chat/NovoChat/areaonline/solucoes.php"
        />
        <OpcoesAjuda
          titulo="Área do cliente"
          descricao="Liberações de emergências, consulta do andamento de suas sugestões, entre outros."
          icone={<IconeAreaClienteSVG width={38} height={38} />}
          link="https://www.melion.com.br/chat/NovoChat/areaonline/login.php"
        />
      </AreaOpcoesAjuda>
      <Perguntas titulo="Perguntas Frequentes" />
    </Conteudo>
  )
}

export { MiddleDuvidas }
