import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface INfsIconSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function NfsIconSVG({
  color = 'var(--blue-800)',
  width = 46,
  height = 40,
}: INfsIconSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_211_3554)" fill={color}>
        <path d="M28.244 29.949c-.782 0-1.61-.103-2.392-.36l.46-1.948a6.272 6.272 0 001.886.308c3.91 0 7.084-3.539 7.084-7.898s-3.174-7.897-7.084-7.897a5.84 5.84 0 00-1.886.307l-.46-1.948a7.738 7.738 0 012.392-.36c4.922 0 8.878 4.462 8.878 9.898s-4.002 9.898-8.878 9.898z" />
        <path d="M29.762 40.051c-1.518 0-2.852-1.077-3.313-2.666l-.046-.154c-.183 0-.322-.052-.505-.103l.276-2c.322.051.643.103.966.103l.598.051.413 1.385c.23.82.966 1.384 1.748 1.282l2.99-.308c.782-.102 1.38-.718 1.472-1.59l.323-2.564.367-.256a14.886 14.886 0 002.209-1.744l.367-.359 2.162.616c.736.205 1.564-.206 1.886-.975l1.335-2.974a1.962 1.962 0 00-.414-2.257l-1.242-1.179.138-.615c.23-1.026.367-2.052.414-3.077l.046-.616 1.196-.615c.735-.36 1.103-1.23.92-2.051l-.599-3.282c-.138-.82-.828-1.436-1.563-1.488l-1.61-.05-.276-.462c-.507-.975-1.15-1.898-1.84-2.77l-.368-.41.506-1.744c.23-.82-.046-1.743-.736-2.153l-2.577-1.693c-.644-.41-1.518-.256-2.024.308L31.97 5.128l-.506-.154a14.278 14.278 0 00-2.576-.41l-.552-.051-.69-1.539a1.762 1.762 0 00-1.564-1.025L26.035 0h.093c1.334 0 2.575.872 3.128 2.154l.23.461c.643.052 1.241.154 1.886.308l.506-.564c1.103-1.23 2.898-1.538 4.231-.667l2.576 1.693c1.426.923 2.024 2.769 1.518 4.512l-.184.667c.553.667 1.013 1.436 1.473 2.205h.598c1.61.051 2.943 1.282 3.311 3.077L46 17.128c.322 1.795-.46 3.539-1.931 4.308l-.276.102c-.047.77-.184 1.539-.323 2.257l.368.359c1.242 1.18 1.564 3.077.828 4.666l-1.288 2.975c-.598 1.282-1.794 2.102-3.082 2.102-.276 0-.598-.05-.873-.102l-1.242-.36c-.553.514-1.15.975-1.748 1.386l-.184 1.64c-.23 1.795-1.518 3.129-3.128 3.283l-2.99.307h-.368zM22.31 37.846h-6.624a.329.329 0 01-.322-.205c-.046-.103-.092-.205 0-.41l2.07-4.103h4.876v-2H4.6c-1.518 0-2.714-1.384-2.714-3.077v-1.077h20.47v-2H1.84V5.744c0-1.693 1.242-3.077 2.76-3.077h17.71v-2H4.6C2.116.667.046 2.923.046 5.744v22.358c0 2.77 2.024 5.077 4.554 5.077h10.764L13.8 36.256a2.769 2.769 0 000 2.41c.414.77 1.104 1.18 1.886 1.18h6.624v-2z" />
        <path d="M22.31 14.051H9.2v2h13.11v-2zM22.31 9.077H9.2v2h13.11v-2zM22.31 19.026H9.2v2h13.11v-2z" />
      </g>
      <defs>
        <clipPath id="clip0_211_3554">
          <path fill="#fff" d="M0 0H46V40H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
