import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Header } from '../components/Header'
import { NossosClientes } from './nossosClientes'
import { Downloads } from './downloads'
import { Home } from './home'
import { Modulos } from './modulos'
import { NossaEmpresa } from './nossaEmpresa'
import { Duvidas } from './duvidas'
import PageFaleConosco from './faleConosco'

function Rotas() {
  return (
    <Routes>
      <Route path="/" element={<Header />}>
        <Route path="/home" element={<Home />} />
        <Route path="/empresa" element={<NossaEmpresa />} />
        <Route path="/clientes" element={<NossosClientes />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/modulos" element={<Modulos />} />
        <Route path="/duvidas" element={<Duvidas />} />
        <Route path="/faleconosco" element={<PageFaleConosco />} />
      </Route>
    </Routes>
  )
}

export { Rotas }
