import React from 'react'
import { Item, BigItem } from './styles'

interface Props {
  item: string
  isBig?: boolean
}

function MenuItem({ item, isBig }: Props) {
  return <>{isBig ? <BigItem>{item}</BigItem> : <Item>{item}</Item>}</>
}

export { MenuItem }
