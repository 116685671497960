import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IDataIconProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function DataIcon({
  color = '#FFFFFF',
  width = 42,
  height = 42,
}: IDataIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.53325 20L6.7565 12.503L12.332 17.3705C12.5005 17.5205 12.7007 17.6305 12.9177 17.6924C13.1346 17.7543 13.3628 17.7664 13.5851 17.7279C13.8074 17.6894 14.0181 17.6013 14.2016 17.4701C14.3851 17.3388 14.5367 17.1679 14.645 16.97L19.8725 9.16775L18.6268 8.33225L13.3768 16.1683L13.3243 16.2455L7.748 11.378C7.57951 11.2289 7.37968 11.1195 7.16326 11.058C6.94684 10.9964 6.71936 10.9843 6.49762 11.0224C6.27588 11.0606 6.06554 11.1481 5.88214 11.2784C5.69873 11.4088 5.54695 11.5786 5.438 11.7755L2 17.8873V0.5H0.5V20C0.500397 20.3977 0.65856 20.779 0.939778 21.0602C1.221 21.3414 1.6023 21.4996 2 21.5H21.5V20H2.53325Z"
        fill={color}
      />
    </svg>
  )
}
