import styled from 'styled-components'

export const AreaFerramenta = styled.div`
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.3));
  cursor: pointer;
  user-select: none;
  &:hover .AreaFerramenta {
    background: ${({ theme }) => theme.hoverBgColor};
  }
  &:hover > .IconeFerramenta > div {
    background-color: ${({ theme }) => theme.hoverIconBgColor};
  }
  &:hover > div > p {
    color: ${({ theme }) => theme.hoverTextColor};
  }
  &:hover .SVGColor {
    fill: ${({ theme }) => theme.hoverTextColor};
  }
  &:hover .SVGCheckColor {
    fill: ${({ theme }) => theme.hoverIconBgColor};
  }
`
export const TextoFerramenta = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 240px;
  gap: 16px;
  padding: 50px 16px 24px 16px;
  background-color: ${({ theme }) => theme.bgColor};
  overflow: show;
  border-radius: 5px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 85% 100%, 0% 100%);
`
export const TituloFerramenta = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  color: ${({ theme }) => theme.titleColor};
`
export const DescricaoFerramenta = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.descriptionColor};
`

export const IconeFerramenta = styled.div`
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
  position: absolute;
  top: -25px;
  left: 92px;
  height: auto;
  width: auto;
`
