import React, { useEffect } from 'react'
import { CardVantagens } from '../components/CardVantagens'
import { CardModulos } from '../components/CardModulos'
import { SobreCicom } from '../components/SobreCicom'
import { Gerencie } from '../components/Gerencie'
import { TopHome } from '../components/TopHome'
import { Perguntas } from '../components/Perguntas'
import { useLocation } from 'react-router-dom'
// import { Depoimentos } from '../components/Depoimentos'

function Home() {
  const location = useLocation()

  useEffect(() => {
    function scrollToDiv(divID: string) {
      const divDestino = document.getElementById(divID)
      divDestino?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
    if (location.hash === '#gerencieFacil') {
      scrollToDiv('gerencieFacil')
    }
  }, [location])

  return (
    <>
      <TopHome />
      <CardVantagens />
      <CardModulos />
      {/* <Depoimentos /> */}
      <Gerencie />
      <Perguntas titulo="Perguntas frequentes" home={true} />
      <SobreCicom />
    </>
  )
}

export { Home }
