import styled from 'styled-components'
import fundoTopDuvidas from '../../assets/imgs/fundoTopDuvidas.jpg'

export const Conteudo = styled.div`
  width: 100%;
  background-image: url(${fundoTopDuvidas});
  background-color: rgb(20, 11, 53);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 150px;
`

//  Areas ================================================================================

export const AreaTexto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

//  Texto ================================================================================

export const Titulo = styled.p`
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  color: var(--white);
`
export const Subtitulo = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  color: var(--white);
  opacity: 0.8;
`
