import styled from 'styled-components'

export const Conteudo = styled.div`
  width: 100%;
  height: auto;
  background-color: var(--grey-100);
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 400px) {
    padding: 70px 0px;
  }
`
export const AreaTitulo = styled.div`
  width: 537px;
  height: 61px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @media (max-width: 600px) {
    width: 390px;
    height: 76px;
  }
  @media (max-width: 400px) {
    width: 300px;
  }
  @media (max-width: 300px) {
    width: 200px;
  }
`
export const Titulo = styled.p`
  font-size: 24px;
  font-weight: 600;
  @media (max-width: 600px) {
    font-size: 20px;
  }
  @media (max-width: 400px) {
    font-size: 18px;
  }
`

export const GridModulos = styled.div`
  margin-top: 40px;
  display: grid;
  max-width: 1000px;
  max-height: 650px;
  grid-template-columns: repeat(4, 211px);
  grid-template-rows: repeat(3, 160px);
  grid-gap: 40px;
  @media (max-width: 1000px) {
    max-width: 750px;
    max-height: 810px;
    grid-template-columns: repeat(3, 211px);
    grid-template-rows: repeat(4, 160px);
  }
  @media (max-width: 750px) {
    max-width: 470px;
    max-height: 1200px;
    grid-template-columns: repeat(2, 211px);
    grid-template-rows: repeat(6, 160px);
    grid-gap: 32px 32px;
  }
  @media (max-width: 510px) {
    max-width: 330px;
    max-height: 890px;
    grid-template-columns: 156px 156px;
    grid-template-rows: repeat(6, 132px);
    grid-gap: 16px;
  }
  @media (max-width: 350px) {
    max-width: 170px;
    max-height: 1770px;
    grid-template-columns: 156px;
    grid-template-rows: repeat(12, 132px);
    grid-gap: 16px;
  }
`

export const AreaSaibaMais = styled.div`
  margin-top: 56px;
  width: auto;
  border-bottom: solid 2px var(--black);
`
export const SaibaMaisTemp = styled.p`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
`

// Modulos ======================================================================
export const AreaModulo = styled.div`
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
  width: 211px;
  height: 160px;
  @media (max-width: 510px) {
    width: 156px;
    height: 131px;
  }
`
export const DivModulo = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 32px;
  left: 0px;
  top: 40px;
  width: 211px;
  height: 120px;
  font-size: 18px;
  background-color: var(--white);
  clip-path: path(
    'M0.5 5C0.5 2.51472 2.51472 0.5 5 0.5H206C208.485 0.5 210.5 2.51472 210.5 5V94.9854C210.5 96.3298 209.899 97.6037 208.861 98.4585L197.389 107.91L197.386 107.913L184.313 118.497C183.512 119.146 182.513 119.5 181.482 119.5H5.00001C2.51473 119.5 0.5 117.485 0.5 115V5Z'
  );
  @media (max-width: 510px) {
    top: 28px;
    width: 156px;
    font-size: 16px;
    height: 103px;
    clip-path: path(
      'M0.5 5C0.5 2.51472 2.51472 0.5 5 0.5H151C153.485 0.5 155.5 2.51472 155.5 5V81.4175C155.5 82.6456 154.998 83.8205 154.11 84.6694L145.827 92.5921L145.824 92.5951L136.586 101.279C135.751 102.063 134.649 102.5 133.504 102.5H5.00001C2.51472 102.5 0.5 100.485 0.5 98V5Z'
    );
  }
`
export const NomeModulo = styled.span`
  font-size: 20px;
  color: var(--black);
`
export const DivLogoModulo = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 67px;
  top: 0px;
  width: 76px;
  height: 80px;
  background-color: var(--blue-800);
  clip-path: path(
    'M0 5C0 2.23858 2.23858 0 5 0H71C73.7614 0 76 2.23858 76 5V38V58.9289C76 60.255 75.4732 61.5268 74.5355 62.4645L66.5 70.5L58.4645 78.5355C57.5268 79.4732 56.255 80 54.9289 80H38H5C2.23858 80 0 77.7614 0 75V5Z'
  );
  @media (max-width: 510px) {
    left: 50px;
    width: 56px;
    font-size: 16px;
    height: 56px;
    clip-path: path(
      'M0 5C0 2.23858 2.23858 0 5 0H51C53.7614 0 56 2.23858 56 5V26.6V40.5534C56 41.924 55.4374 43.2345 54.4437 44.1784L49 49.35L43.4474 54.625C42.5181 55.5078 41.2853 56 40.0036 56H28H5C2.23858 56 0 53.7614 0 51V5Z'
    );
  }
`
