import React, { useState } from 'react'
import { ArrowDownSVG } from '../Svg/arrowDownSVG'
import {
  AccordionAnswer,
  AccordionExpand,
  AccordionQuestion,
  Container,
  IconArea,
  Linha,
} from './styles'

interface IAccordionProps {
  question: string | React.ReactNode
  answer: string | React.ReactNode
  questionBGColor?: string
  answerBGColor?: string
  answerLineColor?: string
}

function Accordion({
  question,
  answer,
  questionBGColor,
  answerBGColor,
  answerLineColor,
}: IAccordionProps) {
  const [toggle, setToggle] = useState<boolean>(false)

  function changeToggle(e: React.MouseEvent<HTMLElement>) {
    setToggle(!toggle)
    const AccordionQuestion: HTMLElement = e.currentTarget as HTMLElement
    const perguntaAcima = AccordionQuestion.parentNode?.previousSibling
      ?.previousSibling as Element

    // scrolla a tela para centralizar a resposta
    if (!toggle) {
      if (perguntaAcima) {
        perguntaAcima.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      } else {
        const topoDivPerguntas = document.getElementById('Questions')
        topoDivPerguntas?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    }

    if (toggle) {
      AccordionQuestion.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <Container>
      <AccordionQuestion
        onClick={(e: React.MouseEvent<HTMLElement>) => changeToggle(e)}
        questionBGColor={questionBGColor}
      >
        {question}
        <IconArea expand={toggle}>
          <ArrowDownSVG />
        </IconArea>
      </AccordionQuestion>
      <AccordionExpand expand={toggle}>
        <Linha answerLineColor={answerLineColor} />
        <AccordionAnswer answerBGColor={answerBGColor}>
          {answer}
        </AccordionAnswer>
      </AccordionExpand>
    </Container>
  )
}

export { Accordion }
