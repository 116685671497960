import { IconsModulos } from '../IconsModulos'
import { BoxShadow, Container, DivIcone } from './styles'
import { ReactNode } from 'react'

interface INoteModuloProps {
  codeIcon: number
  children: ReactNode
  id: string
}

const NoteModulo = ({ id, codeIcon, children }: INoteModuloProps) => {
  return (
    <BoxShadow>
      <Container id={id}>
        <DivIcone>
          <IconsModulos codeModulo={codeIcon} />
        </DivIcone>
        {children}
      </Container>
    </BoxShadow>
  )
}

export { NoteModulo }
