import styled from 'styled-components'

export const Card = styled.div`
  height: 378px;
  width: 328px;
  padding: 24px 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  @media (max-width: 350px) {
    padding: 16px 12px;
    width: 260px;
  }
`

export const AreaTextoCard = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

export const AreaTituloCard = styled.div`
  position: relative;
  width: 254px;
  height: auto;
  padding: 0px 64px;
  text-align: center;
  @media (max-width: 350px) {
    padding: 0px 32px;
    width: 100%;
  }
`

export const TituloCard = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  color: var(--black);
  @media (max-width: 350px) {
    font-size: 22px;
    line-height: 25px;
  }
`
export const AreaSubtituloCard = styled.div`
  position: relative;
  width: 100%;
  height: 176px;
  text-align: center;
`
export const SubtituloCard = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: var(--grey-600);
`

export const Lista = styled.ul`
  text-align: left;
  margin-left: 16px;
`
export const ItemLista = styled.li`
  margin-bottom: 11px;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: var(--grey-600);
`
