import React from 'react'
import { CicomApoia } from '../components/CicomApoia'
import { MiddleNossaEmpresa } from '../components/MiddleNossaEmpresa'
import { TopNossaEmpresa } from '../components/TopNossaEmpresa'

function NossaEmpresa() {
  return (
    <>
      <TopNossaEmpresa />
      <MiddleNossaEmpresa />
      <CicomApoia bgcolor={'var(--white)'} />
    </>
  )
}

export { NossaEmpresa }
