import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IEstoqueMultilojasSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function EstoqueMultilojasSVG({
  color = 'var(--white)',
  width = 40,
  height = 40,
}: IEstoqueMultilojasSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-3 -3 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.908 4L4 10.752V28H28V10.752L22.092 4H9.908ZM9.454 2H22.546C22.6883 1.9999 22.829 2.03017 22.9587 2.08881C23.0884 2.14744 23.2041 2.23308 23.298 2.34L29.752 9.718C29.9116 9.90008 29.9997 10.1339 30 10.376V29C30 29.2652 29.8946 29.5196 29.7071 29.7071C29.5196 29.8946 29.2652 30 29 30H3C2.73478 30 2.48043 29.8946 2.29289 29.7071C2.10536 29.5196 2 29.2652 2 29V10.376C1.9998 10.1332 2.08795 9.8986 2.248 9.716L8.7 2.344C8.79379 2.2361 8.90962 2.14955 9.03969 2.09021C9.16975 2.03087 9.31104 2.00011 9.454 2Z"
        fill={color}
      />
      <path d="M2 10H30V12H2V10Z" fill={color} />
      <path
        d="M14 10.246V20H18V10.246L16.44 4H15.56L14 10.246ZM14 2H18L20 10V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H13C12.7348 22 12.4804 21.8946 12.2929 21.7071C12.1054 21.5196 12 21.2652 12 21V10L14 2Z"
        fill={color}
      />
    </svg>
  )
}
