import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface ITaxIconProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function TaxIcon({
  color = '#FFFFFF',
  width = 44,
  height = 44,
}: ITaxIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_306_1157)">
        <path
          d="M10.5937 0.332804C5.50312 0.951553 1.39219 4.78593 0.46875 9.77343C0.328125 10.5562 0.28125 11.0578 0.28125 11.9062C0.28125 14.6156 1.12969 17.0437 2.85 19.2422C3.22969 19.725 4.31719 20.7844 4.84219 21.1781C7.66406 23.3016 11.0484 24.0375 14.5219 23.2922C15.7078 23.0391 17.3203 22.3406 18.3844 21.6281C20.7281 20.0484 22.3969 17.7562 23.1562 15.0656C23.6531 13.3125 23.7141 11.2172 23.3156 9.42655C22.9594 7.80468 22.2328 6.22499 21.2203 4.87968C20.7844 4.29843 19.8328 3.31874 19.2422 2.8453C17.6953 1.59843 15.8672 0.778116 13.8703 0.421865C13.3969 0.33749 13.0734 0.314053 12.1172 0.299992C11.475 0.295303 10.7859 0.309366 10.5937 0.332804ZM13.0078 1.73437C14.9531 1.94999 16.8516 2.7328 18.3422 3.93749L18.7125 4.2328L15.7641 7.18124C14.1469 8.79843 12.8062 10.125 12.7922 10.125C12.7734 10.125 12.6281 9.74999 12.4687 9.29062C12.3047 8.83593 12.1641 8.4328 12.15 8.39999C12.1359 8.3578 11.9344 8.34374 11.3625 8.34374H10.5891L10.5047 8.54062C10.4625 8.65312 9.95156 10.0641 9.37969 11.6766L8.32969 14.6156L6.28594 16.6594L4.24219 18.7031L3.93281 18.3141C2.84062 16.9594 2.1375 15.3844 1.82344 13.6078C1.67812 12.8062 1.66875 11.175 1.79531 10.3828C2.31562 7.1953 4.17187 4.52812 6.97031 2.9578C8.05781 2.34843 9.50625 1.88437 10.7812 1.73905C11.2828 1.6828 12.5109 1.67812 13.0078 1.73437ZM20.2453 5.95312C21.7031 7.9828 22.3641 10.4391 22.1203 12.9187C21.8109 16.0875 20.0531 18.9141 17.3062 20.6391C15.4922 21.7781 13.1391 22.3312 10.9875 22.1203C9.4875 21.975 8.01094 21.4969 6.73594 20.7516C6.28594 20.4844 5.38594 19.8375 5.32031 19.7344C5.29687 19.6969 7.80937 17.1516 12.4969 12.4641L19.7109 5.24999L19.8187 5.38124C19.875 5.45155 20.0672 5.70937 20.2453 5.95312ZM11.5687 10.5937L11.7562 11.1797L11.1422 11.7984C10.8047 12.1406 10.5234 12.4125 10.5141 12.4078C10.5094 12.3984 10.6875 11.8406 10.9125 11.1656C11.1328 10.4906 11.3344 9.95624 11.3484 9.97499C11.3672 9.99374 11.4656 10.2703 11.5687 10.5937Z"
          fill={color}
        />
        <path
          d="M2.85938 8.95312V9.5625H3.86719H4.875V12.3047V15.0469H5.57812H6.28125V12.3047V9.5625H7.26562H8.25V8.95312V8.34375H5.55469H2.85938V8.95312Z"
          fill={color}
        />
        <path
          d="M19.2469 8.44687C19.2094 8.50781 18.9234 9.00469 18.6187 9.55312L18.0562 10.5516L17.7891 10.0687C17.6391 9.80156 17.4562 9.47344 17.3766 9.33281L17.2359 9.08437L16.7437 9.57656L16.2516 10.0734L16.7391 10.8516C17.0062 11.2828 17.2219 11.6484 17.2172 11.6672C17.2125 11.6906 16.7344 12.45 15.2016 14.8687L15.0938 15.0469H15.9H16.7109L17.3672 13.9219C17.7281 13.3031 18.0328 12.7969 18.0469 12.7969C18.0609 12.7969 18.3516 13.3031 18.6984 13.9219L19.3266 15.0469H20.1656H21.0094L20.9016 14.8687C20.8406 14.775 20.3625 14.0109 19.8328 13.1719C19.3078 12.3328 18.8766 11.625 18.8766 11.5969C18.8766 11.5641 19.3125 10.8562 19.8422 10.0172C20.3766 9.17812 20.8266 8.45625 20.8406 8.41875C20.8641 8.35312 20.775 8.34375 20.0906 8.34375C19.3359 8.34375 19.3125 8.34844 19.2469 8.44687Z"
          fill={color}
        />
        <path
          d="M13.1437 13.35L12.6234 13.8984L12.8156 14.4703L13.0031 15.0469H13.7437C14.1515 15.0469 14.4844 15.0328 14.4844 15.0141C14.4844 14.9484 13.7109 12.7969 13.6875 12.7969C13.6734 12.7969 13.4297 13.0453 13.1437 13.35Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_306_1157">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
