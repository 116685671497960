import { LineDivisionSVG } from '../Svg/LineDivisionSVG'
import { Note } from '../Note'

import {
  Container,
  ContainerTitulo,
  ContainerGrid,
  ContainerFooter,
  ContainerNoteWrapper,
  Text,
} from './styles'

import { StudentSVG } from '../Svg/studentSVG'
import { TaxIcon } from '../Svg/taxIconSVG'
import { PlanIcon } from '../Svg/planIconSVG'
import { HeadsetSVG } from '../Svg/headsetSVG'
import { DataIcon } from '../Svg/dataIconSVG'
import { ManualIcon } from '../Svg/manualIcon'

export const CardVantagens = () => {
  return (
    <Container id="cardVantagens">
      <ContainerTitulo>
        Veja as vantagens de ter o sistema Mecauto integrado à sua empresa
      </ContainerTitulo>
      <LineDivisionSVG />
      <ContainerGrid>
        <ContainerNoteWrapper className="semTaxa noteWrapper">
          <Note padding="0px 0px">
            <TaxIcon />
          </Note>
          <Text>Sem taxa de adesão</Text>
        </ContainerNoteWrapper>

        <div className="pipe allPipe"></div>

        <ContainerNoteWrapper className="plano noteWrapper">
          <Note padding="0px 0px">
            <PlanIcon />
          </Note>
          <Text>Plano personalizado</Text>
        </ContainerNoteWrapper>

        <div className="pipe2 allPipe"></div>

        <ContainerNoteWrapper className="treinamento noteWrapper">
          <Note padding="0px 4px">
            <StudentSVG />
          </Note>
          <Text>Treinamento para usar o sistema</Text>
        </ContainerNoteWrapper>

        <ContainerNoteWrapper className="suporte noteWrapper">
          <Note padding="0px 0px">
            <HeadsetSVG width={44} height={44} />
          </Note>
          <Text>Suporte qualificado</Text>
        </ContainerNoteWrapper>

        <div className="pipe3 allPipe"></div>

        <ContainerNoteWrapper className="migracao noteWrapper">
          <Note padding="0px 7px">
            <DataIcon />
          </Note>
          <Text>Análise para migração de dados</Text>
        </ContainerNoteWrapper>

        <div className="pipe4 allPipe"></div>

        <ContainerNoteWrapper className="manual noteWrapper">
          <Note padding="0px 4px">
            <ManualIcon />
          </Note>
          <Text>Manuais de ajuda</Text>
        </ContainerNoteWrapper>
      </ContainerGrid>
      <ContainerFooter>
        E caso você indique o sistema e ele seja adquirido, você ganha
        bonificação!
      </ContainerFooter>
    </Container>
  )
}
