import React from 'react'
import { Button } from '../Button'

import { CloudDownloadSVG } from '../Svg/CloudDownloadSVG'
import { ExclamationCircleSVG } from '../Svg/exclamationCircleSVG'
import { TeamViewerSVG } from '../Svg/teamViewerSVG'
import {
  AreaAcessoRemoto,
  AreaBotoes,
  AreaIconesAcessoRemoto,
  AreaSubtitulo,
  AreaTexto,
  AreaTextoAcessoRemoto,
  AreaTitulo,
  Conteudo,
  Destaque,
  DivBotao,
  LinkIconeAcessoRemoto,
  Imagem,
  Subtitulo,
  TextoAcessoRemoto,
  Titulo,
  DivIconeAcessoRemoto,
  GridDownloads,
} from './styles'
import downloadsImg from '../../assets/imgs/downloadsImg.png'
import { Link } from 'react-router-dom'
import { KHelpDeskSVG } from '../Svg/kHelpDeskSVG'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gtag: any
  }
}

function TopDownloads() {
  function handleDownloadClick() {
    window.gtag('event', 'conversion', {
      send_to: 'AW-992531803/b_uHCO3AlwIQ26qj2QM',
      value: 1.0,
      currency: 'BRL',
      transaction_id: '',
    })

    window.open('https://cicom.com.br/sistemas/Instal_mecauto.exe', '_self')
  }

  return (
    <Conteudo>
      <GridDownloads>
        <AreaTexto>
          <AreaTitulo>
            <Titulo>
              Gerencie sua empresa do setor automotivo com o sistema{' '}
              <Destaque> MECAUTO</Destaque>
            </Titulo>
          </AreaTitulo>
          <AreaSubtitulo>
            <Subtitulo>
              Você pode testar gratuitamente, durante 30 dias!
              <br />E o melhor, você terá <b>total suporte</b> nesse período e
              após a aquisição da licença.
            </Subtitulo>
          </AreaSubtitulo>
          <AreaBotoes>
            <DivBotao onClick={handleDownloadClick} href="#">
              <Button
                buttonType="warning"
                buttonSize="large"
                caption="Baixar agora"
                leftIcon={<CloudDownloadSVG color="var(--black)" />}
              />
            </DivBotao>
            <DivBotao>
              <Link to="/home#gerencieFacil" className="link-botao">
                <Button
                  buttonType="secondary"
                  buttonSize="large"
                  caption="Conheça o sistema"
                  leftIcon={<ExclamationCircleSVG />}
                />
              </Link>
            </DivBotao>
          </AreaBotoes>
          <AreaAcessoRemoto>
            <AreaTextoAcessoRemoto>
              <TextoAcessoRemoto>
                Programas utilizados para suporte e assistência remota.
              </TextoAcessoRemoto>
            </AreaTextoAcessoRemoto>
            <AreaIconesAcessoRemoto>
              <LinkIconeAcessoRemoto
                href="https://cicom.com.br/downloads_cicom/AR_CICOM.exe"
                target="_blank"
              >
                <DivIconeAcessoRemoto>
                  <KHelpDeskSVG color="var(--grey-500)" />
                </DivIconeAcessoRemoto>
              </LinkIconeAcessoRemoto>
              <LinkIconeAcessoRemoto
                href="https://download.teamviewer.com/download/version_11x/TeamViewerQS-idcf982y69.exe"
                target="_blank"
              >
                <DivIconeAcessoRemoto>
                  <TeamViewerSVG color="var(--grey-500)" />
                </DivIconeAcessoRemoto>
              </LinkIconeAcessoRemoto>
            </AreaIconesAcessoRemoto>
          </AreaAcessoRemoto>
        </AreaTexto>
        <Imagem src={downloadsImg} />
      </GridDownloads>
    </Conteudo>
  )
}

export { TopDownloads }
