import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IFinanceiroIconSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function FinanceiroIconSVG({
  color = 'var(--blue-800)',
  width = 40,
  height = 40,
}: IFinanceiroIconSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.928 13.492c.454-.36.994-.622 1.572-.788v5.59a4.504 4.504 0 01-1.572-.786c-.788-.626-1.092-1.362-1.092-2.008 0-.646.304-1.382 1.092-2.008zM21.5 27.324v-5.648c.694.17 1.328.456 1.842.842.854.64 1.158 1.372 1.158 1.982 0 .61-.304 1.342-1.158 1.982a5.068 5.068 0 01-1.842.84v.002z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 .5C9.23.5.5 9.23.5 20S9.23 39.5 20 39.5 39.5 30.77 39.5 20 30.77.5 20 .5zM21.5 8a1.5 1.5 0 10-3 0v1.632a7.671 7.671 0 00-3.44 1.512c-1.424 1.132-2.224 2.7-2.224 4.356 0 1.658.8 3.224 2.226 4.356 1.004.8 2.204 1.294 3.438 1.512v5.956a5.073 5.073 0 01-1.842-.842l-1.758-1.32a1.5 1.5 0 10-1.8 2.4l1.758 1.32c1.066.8 2.338 1.29 3.642 1.5V32a1.5 1.5 0 103 0v-1.62a8.247 8.247 0 003.642-1.498c1.49-1.118 2.358-2.688 2.358-4.382 0-1.694-.868-3.264-2.358-4.382a8.244 8.244 0 00-3.642-1.5v-5.91c.58.164 1.118.426 1.572.786l.83.66a1.5 1.5 0 001.866-2.35l-.83-.66a7.672 7.672 0 00-3.438-1.51V8z"
        fill={color}
      />
    </svg>
  )
}
