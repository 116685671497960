import React from 'react'
import { TextoPergunta } from './styles'

interface IPergunta {
  pergunta: string
}

function Pergunta({ pergunta }: IPergunta) {
  return (
    <>
      <TextoPergunta>{pergunta}</TextoPergunta>
    </>
  )
}

export { Pergunta }
