import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Note } from '../Note'

import {
  DescricaoFerramenta,
  TextoFerramenta,
  IconeFerramenta,
  AreaFerramenta,
  TituloFerramenta,
} from './styles'

interface IProps {
  titulo: string
  descricao: string
  icone: React.ReactNode
  link: string
}

function OpcoesAjuda({ titulo, descricao, icone, link }: IProps) {
  const theme = {
    bgColor: 'var(--white)',
    titleColor: 'var(--black)',
    descriptionColor: 'var(--grey-400)',
    hoverBgColor: 'linear-gradient(180deg, var(--blue-800) 0%, #184AA1 100%);',
    hoverTextColor: 'var(--white)',
    hoverIconBgColor: 'var(--blue-700)',
  }
  console.log(link)

  return (
    <>
      <ThemeProvider theme={theme}>
        <a href={link} className="link-botao">
          <AreaFerramenta>
            <TextoFerramenta className="AreaFerramenta">
              <TituloFerramenta>{titulo}</TituloFerramenta>
              <DescricaoFerramenta>{descricao}</DescricaoFerramenta>
            </TextoFerramenta>
            <IconeFerramenta className="IconeFerramenta">
              <Note padding="0px" bgColor={theme.bgColor}>
                {icone}
              </Note>
            </IconeFerramenta>
          </AreaFerramenta>
        </a>
      </ThemeProvider>
    </>
  )
}

export { OpcoesAjuda }
