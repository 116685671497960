import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background: var(--grey-100);
  padding: 0 62px 96px 62px;
  min-height: 50.4vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-height: 940px) {
    min-height: 51.9vh;
  }
  @media (max-width: 1280px) {
    padding: 0 16px 96px 16px;
  }

  @media (max-width: 360px) {
    padding: 0 0 96px 0;
  }
`
export const ContainerGrid = styled.div`
  display: grid;
  width: 1156px;
  margin: 0 auto;
  justify-content: center;
  grid-template-columns: auto auto auto auto;
  gap: 65px 24px;
  position: relative;
  top: -50px;

  @media (max-width: 1220px) {
    grid-template-columns: auto auto auto;
    width: 861px;
  }

  @media (max-width: 900px) {
    grid-template-columns: auto auto;
    width: 566px;
  }

  @media (max-width: 600px) {
    grid-template-columns: auto;
    width: 328px;
    gap: 24px;
    top: -70px;
  }

  @media (max-width: 360px) {
    width: 250px;
  }
`
export const Titulo = styled.h3`
  font-size: 18px;
  margin: 24px 0 16px 0;
  font-weight: normal;
`
export const Text = styled.span`
  display: block;
  font-size: 14px;
  color: var(--grey-400);
  line-height: 20px;
  letter-spacing: 0.5px;
  white-space: pre-wrap;
`
