import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface ILineDivisionProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
}

export const LineDivisionSVG = ({
  color = '#cecece',
  ...props
}: ILineDivisionProps) => {
  return (
    <svg
      width={80}
      height={5}
      viewBox="0 0 80 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path stroke="#fff" strokeWidth={5} d="M2.18557e-7 2.5L80 2.50001" />
      <path stroke={color} strokeWidth={5} d="M2.18557e-7 2.5L80 2.50001" />
    </svg>
  )
}
