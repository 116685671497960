import styled from 'styled-components'

export const Conteudo = styled.div`
  position: relative;
  width: 100%;
  background-color: var(--grey-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 152px 62px 48px 62px;
  @media (min-height: 940px) {
    padding: 152px 62px 78px 62px;
  }
  @media (max-width: 1200px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (max-width: 1100px) {
    padding-top: 396px;
  }
  @media (max-width: 600px) {
    padding-top: 871px;
  }
`
// Ferramentas de Ajuda ==================================================================

export const AreaOpcoesAjuda = styled.div`
  position: absolute;
  top: -84px;
  max-width: 1060px;
  display: grid;
  grid-template-columns: repeat(4, 240px);
  grid-gap: 64px 24px;
  @media (max-width: 1100px) {
    max-width: 510px;
    grid-template-columns: 240px 240px;
    grid-template-rows: 180px 180px;
  }
  @media (max-width: 600px) {
    max-width: 250px;
    grid-template-columns: 240px;
    grid-template-rows: repeat(4, 180px);
  }
`
