import FaleConosco from '../components/FaleConosco'

const PageFaleConosco = () => {
  return (
    <>
      <FaleConosco />
    </>
  )
}

export default PageFaleConosco
