import { Container } from './styles'

export const VideoYouTube = () => {
  return (
    <Container>
      <iframe
        src="https://www.youtube.com/embed/z06Ca_PJ3No"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </Container>
  )
}
