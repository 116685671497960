import React, { useState } from 'react'
import {
  ContainerSelect,
  InputText,
  Overlay,
  SelectButton,
  SelectItem,
  SelectMenu,
} from './styles'
import { ArrowDownSVG } from '../Svg/arrowDownSVG'
import { ArrowUpSVG } from '../Svg/arrowUpSVG'
import { ufs } from '../../utils/ufs'

interface ISelectUFProps {
  handleFilterUF: (uf: string) => void
}

const SelectUF = ({ handleFilterUF }: ISelectUFProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [ufSelected, setUfSelected] = useState<string>()

  const clearInput = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = ''
  }

  const keyboardSearchUF = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length >= 2) {
      setIsOpen(false)
      handleFilterUF(e.currentTarget.value)
      setUfSelected(e.currentTarget.value.toUpperCase())
    }
  }

  const close = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(false)
  }
  const clickButton = () => {
    const inputText = document.getElementById('inputUF') as HTMLInputElement
    inputText.focus()
    inputText.value = ''
    setIsOpen(!isOpen)
  }
  const handleClickItem = (uf: string) => {
    handleFilterUF(uf)
    setUfSelected(uf.toUpperCase())
    const inputText = document.getElementById('inputUF') as HTMLInputElement
    if (inputText) {
      inputText.value = uf.toUpperCase()
    }
    setIsOpen(false)
  }
  return (
    <>
      <Overlay
        className="alow"
        onClick={(e: React.MouseEvent<HTMLElement>) => close(e)}
      ></Overlay>
      <ContainerSelect>
        <SelectButton onClick={clickButton}>
          <InputText
            id="inputUF"
            type="text"
            maxLength={2}
            autoComplete="off"
            placeholder="Digite ou selecione um estado"
            defaultValue={ufSelected}
            onClick={(e: React.MouseEvent<HTMLInputElement>) => clearInput(e)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              keyboardSearchUF(e)
            }
          />
          {isOpen ? (
            <ArrowUpSVG width={14} height={14} />
          ) : (
            <ArrowDownSVG width={14} height={14} />
          )}
        </SelectButton>
        {isOpen && (
          <SelectMenu>
            <SelectItem onClick={() => handleClickItem('todos')} id={'todos'}>
              Todos
            </SelectItem>
            {ufs.map((uf) => {
              return (
                <SelectItem
                  onClick={() => handleClickItem(uf.value)}
                  key={uf.value}
                  id={uf.value}
                >
                  {uf.label}
                </SelectItem>
              )
            })}
          </SelectMenu>
        )}
      </ContainerSelect>
    </>
  )
}
export { SelectUF }
