import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IHeartSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function HeartSVG({
  color = 'var(--blue-600)',
  width = 36,
  height = 36,
}: IHeartSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="none"></rect>
      <path
        d="M133.7,211.9l81-81c19.9-20,22.8-52.7,4-73.6a52,52,0,0,0-75.5-2.1L128,70.5,114.9,57.3c-20-19.9-52.7-22.8-73.6-4a52,52,0,0,0-2.1,75.5l83.1,83.1A8.1,8.1,0,0,0,133.7,211.9Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="24"
      ></path>
    </svg>
  )
}
