import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Rotas } from './pages/routes'
import { GlobalStyles } from './GlobalStyles'
import { ScrollToTop } from './components/ScrollToTop'

export function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      {/* <Router basename="/sitecicom"> */}
      <ScrollToTop />
      <GlobalStyles />
      <Rotas />
    </Router>
  )
}
