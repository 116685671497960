import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IArrowDownSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function ArrowDownSVG({
  color = 'var(--black)',
  width = 18,
  height = 10,
}: IArrowDownSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0.200048L9.00001 6.20005L3.00001 0.200048L0.600006 1.40005L9.00001 9.80005L17.4 1.40005L15 0.200048Z"
        fill={color}
      />
    </svg>
  )
}
