import React from 'react'
import { MenuItem } from './menuItens'
import { DivBtn, NavMenu, Ul } from './styles'
import { NavLink } from 'react-router-dom'
import { DownloadSVG } from '../../Svg/DownloadSVG'
import { Button } from '../../Button'

interface Props {
  aparecerMenu: Boolean
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gtag: any
  }
}

function Menu(props: Props) {
  function handleDownloadClick() {
    window.gtag('event', 'conversion', {
      send_to: 'AW-992531803/b_uHCO3AlwIQ26qj2QM',
      value: 1.0,
      currency: 'BRL',
      transaction_id: '',
    })

    window.open('https://cicom.com.br/sistemas/Instal_mecauto.exe', '_self')
  }

  return (
    <NavMenu>
      <Ul aparecerMenu={props.aparecerMenu}>
        <NavLink
          to="home"
          className={({ isActive }) =>
            'nav-link' + (isActive ? '_selected' : '')
          }
        >
          <MenuItem item="Home" />
        </NavLink>
        <NavLink
          to="empresa"
          className={({ isActive }) =>
            'nav-link' + (isActive ? '_selected' : '')
          }
        >
          <MenuItem isBig={true} item="Nossa empresa" />
        </NavLink>
        <NavLink
          to="clientes"
          className={({ isActive }) =>
            'nav-link' + (isActive ? '_selected' : '')
          }
        >
          <MenuItem isBig={true} item="Nossos clientes" />
        </NavLink>
        <NavLink
          to="downloads"
          className={({ isActive }) =>
            'nav-link' + (isActive ? '_selected' : '')
          }
        >
          <MenuItem item="Downloads" />
        </NavLink>
        <NavLink
          to="modulos"
          className={({ isActive }) =>
            'nav-link' + (isActive ? '_selected' : '')
          }
        >
          <MenuItem item="Módulos" />
        </NavLink>
        <NavLink
          to="duvidas"
          className={({ isActive }) =>
            'nav-link' + (isActive ? '_selected' : '')
          }
        >
          <MenuItem item="Dúvidas" />
        </NavLink>
        <NavLink
          to="faleconosco"
          className={({ isActive }) =>
            'nav-link' + (isActive ? '_selected' : '')
          }
        >
          <MenuItem isBig={true} item="Fale conosco" />
        </NavLink>
        <DivBtn onClick={handleDownloadClick} href="#">
          <Button
            caption="Baixar agora"
            leftIcon={<DownloadSVG width={24} height={24} />}
            buttonType="warning"
            buttonSize="medium"
            hint="testar o sistema agora!"
          />
        </DivBtn>
      </Ul>
    </NavMenu>
  )
}

export { Menu }
