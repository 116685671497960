import styled from 'styled-components'

interface Props {
  aparecerMenu: Boolean
}

export const Cabecalho = styled.header<Props>`
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  transition: width 0.3s, height 0.3s;
  padding: 10px 60px;
  background-color: var(--blue-850);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  z-index: 8;

  @media (max-width: 1300px) {
    padding: 10px 32px;
  }
  @media (max-width: 1200px) {
    padding: 10px 22px;
  }
  @media (max-width: 1000px) {
    position: ${(props: Props) => (props.aparecerMenu ? 'fixed' : 'relative')};
    width: ${(props: Props) => (props.aparecerMenu ? '90%' : '100%')};
    height: ${(props: Props) => (props.aparecerMenu ? '410px' : '80px')};
    flex-direction: column;
    justify-content: flex-start;
    align-items: ${(props: Props) =>
      props.aparecerMenu ? ' flex-start' : 'center'};
  }
`

export const DivLogo = styled.div<Props>`
  height: auto;
  width: auto;
  @media (max-width: 1000px) {
    width: ${(props: Props) =>
      props.aparecerMenu ? 'calc(100% + 64px)' : 'auto'};
    margin-left: ${(props: Props) => (props.aparecerMenu ? '-32px' : '0px')};
    padding-left: ${(props: Props) => (props.aparecerMenu ? '32px' : '0px')};
    ${(props: Props) =>
      props.aparecerMenu ? 'box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);' : ''};
  }
`

export const IconHamburguer = styled.img<Props>`
  transition: left 0.3s;
  position: absolute;
  display: none;
  top: 28px;
  left: ${(props: Props) => (props.aparecerMenu ? '85%' : '16px')};
  width: 24px;
  height: 24px;
  @media (max-width: 1000px) {
    display: block;
    cursor: pointer;
    left: ${(props: Props) => (props.aparecerMenu ? '90%' : '16px')};
  }
  @media (max-width: 500px) {
    display: block;
    cursor: pointer;
    left: ${(props: Props) => (props.aparecerMenu ? '87%' : '16px')};
  }
`
