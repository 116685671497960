import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface PlayCircleFilledSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function PlayCircleFilledSVG({
  color = 'var(--blue-600)',
  width = 24,
  height = 24,
}: PlayCircleFilledSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.66632C6.20156 1.66632 1.5 6.36788 1.5 12.1663C1.5 17.9648 6.20156 22.6663 12 22.6663C17.7984 22.6663 22.5 17.9648 22.5 12.1663C22.5 6.36788 17.7984 1.66632 12 1.66632ZM15.3773 12.328L10.2586 16.0523C10.2305 16.0724 10.1975 16.0845 10.163 16.0871C10.1285 16.0896 10.094 16.0827 10.0633 16.0669C10.0325 16.0512 10.0067 16.0273 9.98863 15.9978C9.97057 15.9683 9.96099 15.9345 9.96094 15.8999V8.45616C9.96082 8.42154 9.9703 8.38756 9.98832 8.35799C10.0063 8.32842 10.0322 8.30442 10.063 8.28864C10.0938 8.27286 10.1284 8.26593 10.1629 8.26861C10.1975 8.27129 10.2306 8.28348 10.2586 8.30382L15.3773 12.0257C15.4015 12.0428 15.4213 12.0655 15.4349 12.0918C15.4485 12.1181 15.4556 12.1472 15.4556 12.1769C15.4556 12.2065 15.4485 12.2357 15.4349 12.262C15.4213 12.2883 15.4015 12.3109 15.3773 12.328Z"
        fill={color}
      />
      <path
        d="M12 1.66632C6.20156 1.66632 1.5 6.36788 1.5 12.1663C1.5 17.9648 6.20156 22.6663 12 22.6663C17.7984 22.6663 22.5 17.9648 22.5 12.1663C22.5 6.36788 17.7984 1.66632 12 1.66632ZM15.3773 12.328L10.2586 16.0523C10.2305 16.0724 10.1975 16.0845 10.163 16.0871C10.1285 16.0896 10.094 16.0827 10.0633 16.0669C10.0325 16.0512 10.0067 16.0273 9.98863 15.9978C9.97057 15.9683 9.96099 15.9345 9.96094 15.8999V8.45616C9.96082 8.42154 9.9703 8.38756 9.98832 8.35799C10.0063 8.32842 10.0322 8.30442 10.063 8.28864C10.0938 8.27286 10.1284 8.26593 10.1629 8.26861C10.1975 8.27129 10.2306 8.28348 10.2586 8.30382L15.3773 12.0257C15.4015 12.0428 15.4213 12.0655 15.4349 12.0918C15.4485 12.1181 15.4556 12.1472 15.4556 12.1769C15.4556 12.2065 15.4485 12.2357 15.4349 12.262C15.4213 12.2883 15.4015 12.3109 15.3773 12.328Z"
        fill={color}
        fillOpacity="0.2"
      />
    </svg>
  )
}
