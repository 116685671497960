import styled from 'styled-components'
interface INoteStyleProps {
  bgColor?: string
  padding?: string
}

export const Container = styled.div<INoteStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding || '18px'};
  background-color: ${({ bgColor }) => bgColor || 'var(--blue-100)'};
  border-radius: 5px;
  width: 56px;
  height: 56px;
  clip-path: path(
    'M0 5C0 2.23858 2.23858 0 5 0H51C53.7614 0 56 2.23858 56 5V26.6V40.5534C56 41.924 55.4374 43.2345 54.4437 44.1784L49 49.35L43.4474 54.625C42.5181 55.5078 41.2853 56 40.0036 56H28H5C2.23858 56 0 53.7614 0 51V5Z'
  );
`
