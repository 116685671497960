import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IIconeSolucoesSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  colorCounter?: string
  color?: string
  width?: number
  height?: number
}

export function IconeSolucoesSVG({
  colorCounter = 'var(--white)',
  color = 'var(--blue-800)',
  width = 32,
  height = 19,
}: IIconeSolucoesSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_707)">
        <path
          className="SVGColor"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.67875 16.6475H3.6225C3.37375 16.5837 3.18688 16.3538 3.18688 16.0863V0.58C3.18688 0.261875 3.44875 0 3.76688 0H28.0806C28.3988 0 28.6606 0.261875 28.6606 0.58V16.0837C28.6606 16.3538 28.4762 16.5806 28.225 16.645H31.3213C31.695 16.645 32 16.9494 32 17.3237C32 17.6981 31.695 18.0025 31.3213 18.0025H0.67875C0.305 18.0025 0 17.6981 0 17.3237C0 16.9525 0.305 16.6475 0.67875 16.6475ZM27.855 0.80375V15.9206H4.07312V0.80375H27.855ZM15.0456 16.9013H16.9544C17.3856 16.9013 17.3856 17.5537 16.9544 17.5537H15.0456C14.615 17.5537 14.6131 16.9013 15.0456 16.9013Z"
          fill={color}
        />
        <path
          className="SVGColor"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.96 3.82437C18.4887 3.82437 20.5387 5.87437 20.5387 8.40312C20.5387 10.9319 18.4887 12.9812 15.96 12.9812C13.4312 12.9812 11.3818 10.9319 11.3818 8.40312C11.3818 5.87437 13.4312 3.82437 15.96 3.82437Z"
          fill={color}
        />
        <path
          className="SVGCheckColor"
          d="M18.3789 6.38623H18.7227C17.2583 8.01248 16.1233 9.35311 15.107 11.3137C14.5777 10.1825 14.1064 9.40123 13.0508 8.67686L13.4445 8.58811C14.2333 9.23373 14.6489 9.95061 15.107 10.93C16.042 9.12561 17.0783 7.84623 18.3789 6.38623Z"
          fill={colorCounter}
        />
        <path
          className="SVGCheckColor"
          d="M14.2077 8.03182C14.5595 8.23494 14.7883 8.40307 15.0608 8.70369C15.7683 7.56557 16.5352 6.93494 17.5333 6.03994L17.6308 6.00244H18.7227C17.2589 7.62869 16.1233 8.96932 15.107 10.9299C14.5777 9.79869 14.1058 9.01744 13.0508 8.29369L14.2077 8.03182Z"
          fill={colorCounter}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_707">
          <rect
            width="32"
            height="18.0025"
            fill="white"
            className="SVGCheckColor"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
