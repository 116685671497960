import styled from 'styled-components'
import { darken } from 'polished'

interface IProps {
  textColor?: string
  top?: string
  left?: string
  responsiveHide?: boolean
  hideInPX?: string
  show?: boolean
  marginBottom?: string
}

export const ReturnTop = styled.div<IProps>`
  margin-right: 4px;
  top: ${(props) => props.top || '615px'};
  left: ${(props) => props.left || '88%'};
  height: 80px;
  margin-bottom: ${(props) => props.marginBottom || '-80px'};
  position: sticky;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 120px;
  z-index: 9;
  text-align: center;
  cursor: pointer;
  user-select: none;
  ${(props) =>
    props.responsiveHide
      ? `@media (max-width: ${props.hideInPX}) {
    display: none;
  }}`
      : ''};
  @media (max-width: 1000px) {
    width: 52px;
    height: 52px;
  }

  &:hover svg circle {
    fill: ${darken(0.1, '#0081FE')};
  }
`

export const Span = styled.span<IProps>`
  color: ${(props) => props.textColor || ' var(--black)'};
  @media (max-width: 1000px) {
    display: none;
  }
`
