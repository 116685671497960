import styled from 'styled-components'

export const ConteudoTopEmpresa = styled.div`
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--grey-100);
  @media (max-width: 900px) {
    padding-top: 24px;
    padding-bottom: 40px;
  }
`
export const GridTopEmpresa = styled.div`
  display: grid;
  max-width: 100%;
  grid-template-columns: 487px 500px;
  grid-template-rows: 420px;
  grid-template-areas: 'esquerda direita';
  grid-gap: 63px;
  @media (max-width: 1100px) {
    grid-template-columns: 387px 440px;
    grid-template-rows: 350px;
    grid-template-areas: 'esquerda direita';
    grid-gap: 50px;
  }
  @media (max-width: 900px) {
    grid-template-columns: 402px;
    grid-template-rows: 368px auto;
    grid-template-areas: 'direita' 'esquerda';
    grid-gap: 40px;
  }
  @media (max-width: 450px) {
    grid-template-columns: 342px;
    grid-template-rows: 308px auto;
    grid-template-areas: 'direita' 'esquerda';
    grid-gap: 40px;
  }
  @media (max-width: 350px) {
    grid-template-columns: 260px;
    grid-template-rows: 246px auto;
    grid-template-areas: 'direita' 'esquerda';
    grid-gap: 40px;
  }
`
// direita da grid ===========================================================
export const AreaImagemTopEmpresa = styled.div`
  grid-area: direita;
  width: 100%;
  height: 100%;
`
export const ImagemTopEmpresa = styled.img`
  margin-top: -4px;
  width: 100%;
  height: 100%;
`
// esquerda da grid ==========================================================
export const AreaTextoTopEmpresa = styled.div`
  grid-area: esquerda;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`
// Titulo ====================================================================
export const DivTituloTopEmpresa = styled.div`
  width: 100%;
  height: auto;
`
export const TituloTopEmpresa = styled.p`
  font-size: 40px;
  font-weight: 400;
  line-height: 48.41px;
  color: var(--black);
  @media (max-width: 1100px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (max-width: 900px) {
    text-align: center;
  }
  @media (max-width: 450px) {
    font-size: 24px;
    line-height: 29px;
  }
`
export const Destaque = styled.b`
  font-size: 40px;
  font-weight: 700;
  line-height: 48.41px;
  color: var(--black);
  @media (max-width: 1100px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (max-width: 450px) {
    font-size: 24px;
    line-height: 29px;
  }
`
// Subtitulo ==================================================================
export const DivSubtituloTopEmpresa = styled.div`
  width: 100%;
  height: auto;
  margin-top: 16px;
  margin-bottom: 56px;
  @media (max-width: 900px) {
    margin-bottom: 48px;
  }
  @media (max-width: 450px) {
    margin-bottom: 32px;
  }
`
export const SubtituloTopEmpresa = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: var(--grey-600);
  @media (max-width: 900px) {
    text-align: center;
  }
  @media (max-width: 450px) {
    font-size: 14px;
    line-height: 17.5px;
  }
`

// Cards ===================================================================
export const AreaCardsTopEmpresa = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  gap: 48px;

  @media (max-width: 450px) {
    padding-left: 8px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
  }
`

export const DivCardTopEmpresa = styled.div`
  max-width: 100%;
  max-height: 72px;
  height: auto;
  display: grid;
  grid-template-columns: 32px auto;
  grid-gap: 16px;
  grid-template-areas: 'icone titulo';
`

export const IconeCard = styled.div`
  grid-area: icone;
  width: 100%;
  height: 100%;
`

export const DivTituloCard = styled.div`
  width: 100%;
`
export const TituloCard = styled.p`
  grid-area: titulo;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--blue-850-90);
`
