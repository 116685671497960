import React from 'react'
import {
  AreaSubtituloCard,
  Card,
  AreaTextoCard,
  TituloCard,
  AreaTituloCard,
  SubtituloCard,
  Lista,
  ItemLista,
} from './styles'
import { Note } from '../Note'

interface ICardNossaEmpresaProps {
  icone: React.ReactNode
  titulo: string
  subtitulo?: string
  lista?: Array<string>
}

function CardNossaEmpresa({
  icone,
  titulo,
  subtitulo,
  lista,
}: ICardNossaEmpresaProps) {
  function geraLista(lista: Array<string>) {
    return (
      <Lista>
        {lista.map((item: string, index: number) => (
          <ItemLista key={index}>{item}</ItemLista>
        ))}
      </Lista>
    )
  }

  return (
    <Card>
      <Note padding="0px 0px">{icone}</Note>
      <AreaTextoCard>
        <AreaTituloCard>
          <TituloCard>{titulo}</TituloCard>
        </AreaTituloCard>
        <AreaSubtituloCard>
          {lista ? (
            geraLista(lista)
          ) : (
            <SubtituloCard>{subtitulo}</SubtituloCard>
          )}
        </AreaSubtituloCard>
      </AreaTextoCard>
    </Card>
  )
}

export { CardNossaEmpresa }
