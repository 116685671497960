import styled from 'styled-components'

export const Container = styled.div`
  position: static;
  width: 100%;
  min-height: 600px;
  background: var(--grey-100);
  padding: 0 16px;
  z-index: 2;
`

export const ContainerTable = styled.div`
  position: relative;
  width: 64%;
  margin: 0 auto;
  background: var(--white);
  border-radius: 16px;
  box-shadow: 0px 0px 12px rgb(0, 0, 0, 0.25);
  padding: 32px 40px;
  top: -50px;
  z-index: 2;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1150px) {
    top: -50px;
    height: 600px;
    overflow-y: scroll;
    overflow-x: scroll;
    width: 100%;
  }

  .spin {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Table = styled.table`
  position: relative;
  width: 100%;
  border-spacing: 0;
  tr {
    height: 48px;
  }
  td,
  th {
    font-size: 14px;
    padding: 12px 16px;
    width: 224px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-align: start;
    text-transform: capitalize;
  }
`
export const TituloTable = styled.thead`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 84px;
  left: 0px;
  font-weight: 600;
  color: var(--white);
  background: var(--blue-850);
  box-shadow: 0px -4px 0px var(--white);
  @media (max-width: 1150px) {
    top: -28px;
    left: 0px;
  }

  th {
    border-bottom: none;
  }

  th:first-child {
    border-radius: 8px 0px 0px 8px;
  }
  th:last-child {
    border-radius: 0px 8px 8px 0px;
  }
`

export const BodyTable = styled.tbody``

export const ReturnTop = styled.div`
  top: 615px;
  left: 88%;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  z-index: 9;
  text-align: center;
  cursor: pointer;
  user-select: none;
`
export const Arrow = styled.img`
  position: relative;
`
