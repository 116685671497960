import React from 'react'
import { AreaTexto, Conteudo, Subtitulo, Titulo } from './styles'

function TopDuvidas() {
  return (
    <Conteudo>
      <AreaTexto>
        <Titulo>Dúvidas</Titulo>
        <Subtitulo>
          Esclareça suas dúvidas com nossas ferramentas de ajuda.
        </Subtitulo>
      </AreaTexto>
    </Conteudo>
  )
}

export { TopDuvidas }
