import styled from 'styled-components'

export const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 80px;
  left: 0;
  z-index: 1;
`
export const ContainerSelect = styled.div`
  position: relative;
  width: 250px;
  height: 56px;
  background: var(--white);
  border-radius: 8px;
  margin: 0 auto;
  z-index: 3;
`

export const SelectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  color: black;
  padding: 16px;
  cursor: pointer;
  user-select: none;
  z-index: 3;
`
export const InputText = styled.input`
  width: 100%;
  font-size: 14px;
  text-align: center;
  border: none;
  outline: none;
  z-index: 3;
`

export const Texto = styled.p`
  width: 100%;
  font-size: 14px;
  text-align: center;
`
export const SelectMenu = styled.div`
  width: 250px;
  height: 350px;
  overflow: auto;
  font-size: 12px;
  color: black;
  background: var(--white);
  box-shadow: 0px 0px 5px grey;
  border-radius: 8px;
  position: absolute;
  top: 110%;
  cursor: pointer;
  padding: 1px;
  z-index: 3;
  user-select: none;

  @media (max-width: 600px) {
    width: 209px;
  }
`

export const SelectItem = styled.div`
  width: 100%;
  font-size: 12px;
  color: var(--black);
  border-radius: 8px;
  padding: 10px;
  user-select: none;
  transition: all 0.2s;
  z-index: 3;

  :hover {
    background-color: var(--blue-800);
    color: var(--white);
  }
`
