import Contatos from '../Contatos'
import Formulario from '../Formulario'
import { Container } from './styles'

const FaleConosco = () => {
  return (
    <Container>
      <Contatos />
      <Formulario />
    </Container>
  )
}

export default FaleConosco
