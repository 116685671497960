import React from 'react'
import {
  Conteudo,
  AreaTexto,
  Titulo,
  Subtitulo,
  AreaSubtitulo,
  AreaTitulo,
  Imagem,
  AreaCards,
  GridCards,
  AreaImagem,
} from './styles'
import middleNossaEmpresa from '../../assets/imgs/equipe.png'
import { AlvoSVG } from '../Svg/alvoSVG'
import { CardNossaEmpresa } from '../CardNossaEmpresa'
import { OlhoSVG } from '../Svg/olhoSVG'
import { DiamanteSVG } from '../Svg/diamanteSVG'

function MiddleNossaEmpresa() {
  return (
    <Conteudo>
      <AreaTexto>
        <AreaTitulo>
          <Titulo>
            Nossa filosofia é manter uma relação de amizade com nossos clientes.
          </Titulo>
        </AreaTitulo>
        <AreaSubtitulo>
          <Subtitulo>
            Por isso, fazemos o melhor para ajudá-los e sempre somos
            transparentes em todas as situações. Assim, acreditamos que nossas
            parcerias serão duradouras.
          </Subtitulo>
        </AreaSubtitulo>
        <AreaImagem>
          <Imagem src={middleNossaEmpresa} />
        </AreaImagem>
      </AreaTexto>
      <AreaCards>
        <GridCards>
          <CardNossaEmpresa
            icone={<AlvoSVG width={36} height={36} />}
            titulo="Missão"
            subtitulo="Proporcionar uma experiência única em atendimento humanizado e progredir no desenvolvimento de novas tecnologias, para continuar desenvolvendo um excelente sistema de gerenciamento empresarial para nossos clientes."
          />
          <CardNossaEmpresa
            icone={<OlhoSVG width={36} height={36} />}
            titulo="Visão"
            subtitulo="Continuar sendo a empresa de tecnologia mais eficiente no desenvolvimento de sistemas de gestão para o setor automotivo."
          />
          <CardNossaEmpresa
            icone={<DiamanteSVG width={36} height={36} />}
            titulo="Valores"
            lista={[
              'Ética',
              'Inovação',
              'Respeito',
              'Honestidade',
              'Responsabilidade',
              'Investimento na qualidade de vida das pessoas.',
            ]}
          />
        </GridCards>
      </AreaCards>
    </Conteudo>
  )
}

export { MiddleNossaEmpresa }
