import styled from 'styled-components'
import topPageClientes from '../../assets/imgs/topPageClientes.jpg'

export const ContainerTop = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 100px;
  background: url(${topPageClientes});
  background-color: var(--blue-900);
  background-size: cover;
`

export const ContainerTextTop = styled.div`
  width: 100%;
  height: 139px;
  color: var(--white);
  text-align: center;
  padding: 0px 16x;
`

export const Titulo = styled.h1`
  font-weight: normal;
  font-size: 48px;
  margin-bottom: 25px;

  @media (max-width: 1050px) {
    font-size: 40px;
  }

  @media (max-width: 760px) {
    font-size: 28px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }
`
