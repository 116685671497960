import { ReactNode } from 'react'
import { Container } from './styles'

interface INoteProps {
  children?: ReactNode
  bgColor?: string
  padding?: string
}

const Note = ({ children, bgColor, padding }: INoteProps) => {
  return (
    <Container className="containerIcon" bgColor={bgColor} padding={padding}>
      {children}
    </Container>
  )
}

export { Note }
