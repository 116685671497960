import styled from 'styled-components'

export const Container = styled.div`
  font-size: 18px;
  width: 100%;
  background-color: var(--blue-800);
  color: var(--white);
  padding: 50px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 800px) {
    padding: 48px 16px;
  }
`

export const ContainerTitulo = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 32px;
  padding: 0px 24px;
  @media (max-width: 800px) {
    font-size: 22px;
    padding: 0px 12px;
  }
  @media (max-width: 400px) {
    font-size: 18px;
  }
`

export const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 250px 1px 250px 1px 250px;
  grid-template-areas:
    'semTaxa pipe plano pipe2 treinamento'
    'suporte pipe3 migracao pipe4 manual';
  gap: 40px 24px;
  max-width: 900px;
  margin: 32px 0px 48px 0px;

  .pipe {
    grid-area: pipe;
    margin: auto 0;
  }
  .pipe2 {
    grid-area: pipe2;
    margin: auto 0;
  }
  .pipe3 {
    grid-area: pipe3;
    margin: auto 0;
  }
  .pipe4 {
    grid-area: pipe4;
    margin: auto 0;
  }

  .semTaxa {
    grid-area: semTaxa;
  }

  .plano {
    grid-area: plano;
  }

  .treinamento {
    grid-area: treinamento;
  }

  .suporte {
    grid-area: suporte;
  }

  .migracao {
    grid-area: migracao;
  }
  .manual {
    grid-area: manual;
  }

  .allPipe {
    height: 32px;
    width: 1px;
    background: var(--white);

    @media (max-width: 800px) {
      transform: rotate(90deg);
      margin: 0px 50%;
    }

    @media (max-width: 300px) {
      display: none;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: 200px 1px 200px 1px 200px;
  }
  @media (max-width: 800px) {
    margin: 32px 0px 32px 0px;
    grid-template-columns: 140px 140px;
    grid-template-rows: 135px 48px 135px 48px 135px;
    grid-template-areas:
      'semTaxa plano'
      'pipe pipe2'
      'suporte migracao'
      'pipe3 pipe4'
      'manual treinamento';
    gap: 0px 40px;
    max-width: 360px;
    max-height: 500px;
  }

  @media (max-width: 400px) {
    grid-template-columns: 130px 130px;
    grid-template-rows: 110px 48px 110px 48px 110px;
    gap: 0px 32px;
    max-width: 300px;
    max-height: 600px;
  }

  @media (max-width: 300px) {
    grid-template-columns: 130px;
    grid-template-rows: repeat(6, auto);

    grid-template-areas:
      'semTaxa'
      'plano'
      'suporte'
      'migracao'
      'manual'
      'treinamento';
    gap: 40px 0;
    max-width: 140px;
    max-height: 900px;
  }
`

export const ContainerNoteWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
  }
`

export const Text = styled.span`
  margin-left: 16px;
  text-align: start;
  font-size: 18px;

  @media (max-width: 800px) {
    text-align: center;
    margin-left: 0;
    margin-top: 16px;
  }
  @media (max-width: 400px) {
    text-align: center;
    margin-left: 0;
    margin-top: 16px;
    font-size: 14px;
    line-height: 16.94px;
  }
`

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;

  @media (max-width: 800px) {
    font-size: 16px;
  }
`
