import React from 'react'
import { IconsModulos } from '../IconsModulos'
import { AreaModulo, DivModulo, DivLogoModulo } from './styles'

interface IModulosProps {
  modulo: string
  codIcone: number
}

function ModuloHome({ modulo, codIcone }: IModulosProps) {
  return (
    <AreaModulo>
      <DivModulo>{modulo}</DivModulo>
      <DivLogoModulo>
        <IconsModulos codeModulo={codIcone} color="var(--white)" />
      </DivLogoModulo>
    </AreaModulo>
  )
}

export { ModuloHome }
