import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IInfoSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function InfoSVG({
  color = '#FFFFFF',
  width = 24,
  height = 24,
}: IInfoSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="none"></rect>
      <circle
        cx="128"
        cy="128"
        r="96"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></circle>
      <polyline
        points="120 120 128 120 128 176 136 176"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="24"
      ></polyline>
      <circle cx="126" cy="84" r="12"></circle>
    </svg>
  )
}
