import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface ISatIconSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function SatIconSVG({
  color = 'var(--white)',
  width = 40,
  height = 40,
}: ISatIconSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 2 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6444 8.4812C20.6444 8.0108 20.2636 7.63 19.7932 7.63H4.90281C4.43241 7.63 4.05161 8.0108 4.05161 8.4812C4.05161 8.9516 4.43241 9.3324 4.90281 9.3324H19.7932C20.2636 9.3324 20.6444 8.9516 20.6444 8.4812Z"
        fill={color}
      />
      <path
        d="M16.6572 13.5692C16.6572 13.0988 16.2764 12.718 15.806 12.718H4.90281C4.43241 12.718 4.05161 13.0988 4.05161 13.5692C4.05161 14.0396 4.43241 14.4204 4.90281 14.4204H15.8088C16.2764 14.4176 16.6572 14.0368 16.6572 13.5692Z"
        fill={color}
      />
      <path
        d="M4.90281 17.8196C4.43241 17.8196 4.05161 18.2004 4.05161 18.6708C4.05161 19.1412 4.43241 19.522 4.90281 19.522H10.164C10.6344 19.522 11.0152 19.1412 11.0152 18.6708C11.0152 18.2004 10.6344 17.8196 10.164 17.8196H4.90281Z"
        fill={color}
      />
      <path
        d="M22.3832 22.0752C22.3832 23.0132 21.6216 23.7748 20.6836 23.7748H3.6792C2.7412 23.7748 1.9796 23.0132 1.9796 22.0752V5.92484C1.9796 4.98684 2.7412 4.22524 3.6792 4.22524H4.5304C4.5528 4.22524 4.578 4.22524 4.6004 4.22244H19.7624C19.7848 4.22524 19.81 4.22524 19.8324 4.22524H20.6836C21.6216 4.22524 22.3832 4.98684 22.3832 5.92484V6.61644L24.0828 6.41204V5.92484C24.0828 4.04604 22.5596 2.52564 20.6836 2.52564H20.6388H19.8324H4.5304H3.99H3.6792C1.8032 2.52284 0.279999 4.04604 0.279999 5.92484V22.078C0.279999 23.9568 1.8032 25.4772 3.6792 25.4772H20.6808C22.5596 25.4772 24.08 23.954 24.08 22.078V17.1136L22.3804 17.318L22.3832 22.0752Z"
        fill={color}
      />
      <path
        d="M27.4988 9.89209L25.4632 7.85649C25.1692 7.56249 24.6932 7.56249 24.3992 7.85649L23.1952 9.06049C23.1896 9.06609 23.184 9.07169 23.1784 9.07729L26.278 12.1769C26.2836 12.1713 26.2892 12.1657 26.2948 12.1601L27.4988 10.9561C27.7928 10.6621 27.7928 10.1861 27.4988 9.89209Z"
        fill={color}
      />
      <path
        d="M15.1004 17.1248L15.092 20.2188L18.2616 20.2244L25.6032 12.8828L22.4756 9.75244L15.1004 17.1248ZM18.4296 18.62L17.514 18.69L17.5924 17.7044L16.6208 17.7352L16.7468 16.8896L17.8416 15.7948L19.5468 17.5L18.4296 18.62ZM22.7052 11.2364C22.806 11.3372 22.806 11.4968 22.7052 11.5976L18.7936 15.5092C18.6928 15.61 18.5332 15.61 18.4324 15.5092C18.3316 15.4084 18.3316 15.2488 18.4324 15.148L22.3468 11.2336C22.4448 11.1356 22.6072 11.1356 22.7052 11.2364Z"
        fill={color}
      />
    </svg>
  )
}
