import { SVGAttributes } from 'react'

// eslint-disable-next-line no-undef
interface IFotosIconSVGProps extends SVGAttributes<HTMLOrSVGElement> {
  color?: string
  width?: number
  height?: number
}

export function FotosIconSVG({
  color = 'var(--white)',
  width = 40,
  height = 40,
}: IFotosIconSVGProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-3 -2 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33331 6.66634C3.33331 6.31272 3.47379 5.97358 3.72384 5.72353C3.97389 5.47348 4.31302 5.33301 4.66665 5.33301H27.3333C27.6869 5.33301 28.0261 5.47348 28.2761 5.72353C28.5262 5.97358 28.6666 6.31272 28.6666 6.66634V25.333C28.6666 25.6866 28.5262 26.0258 28.2761 26.2758C28.0261 26.5259 27.6869 26.6663 27.3333 26.6663H4.66665C4.31302 26.6663 3.97389 26.5259 3.72384 26.2758C3.47379 26.0258 3.33331 25.6866 3.33331 25.333V6.66634Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66663 12C9.93184 12 10.1862 11.8946 10.3737 11.7071C10.5613 11.5196 10.6666 11.2652 10.6666 11C10.6666 10.7348 10.5613 10.4804 10.3737 10.2929C10.1862 10.1054 9.93184 10 9.66663 10C9.40141 10 9.14706 10.1054 8.95952 10.2929C8.77198 10.4804 8.66663 10.7348 8.66663 11C8.66663 11.2652 8.77198 11.5196 8.95952 11.7071C9.14706 11.8946 9.40141 12 9.66663 12Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99998 16L13.3333 18.6667L17.3333 14L28.6666 22.6667V25.3333C28.6666 25.687 28.5262 26.0261 28.2761 26.2761C28.0261 26.5262 27.6869 26.6667 27.3333 26.6667H4.66665C4.31302 26.6667 3.97389 26.5262 3.72384 26.2761C3.47379 26.0261 3.33331 25.687 3.33331 25.3333V22.6667L9.99998 16Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}
