import styled from 'styled-components'

interface ICicomApoiaStylesProps {
  bgcolor?: string
  marginTop?: string
  width?: string
  height?: string
}
// Apoio a causas ==============================================================
export const ConteudoCicomApoia = styled.div<ICicomApoiaStylesProps>`
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ bgcolor }) => bgcolor || ' var(--grey-100);'};
  width: 100%;
  height: auto;
  @media (max-width: 1100px) {
    background-color: var(--grey-100);
  }
`
export const AreaApoio = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 380px) {
    width: 95%;
    height: auto;
  }
`

export const DivApoio = styled.div`
  width: 100%;
  height: 66px;
  display: flex;
  flex: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`
export const TextoApoio = styled.p`
  font-size: 28px;
  color: var(--blue-600);
  font-weight: 600;

  @media (max-width: 380px) {
    font-size: 22px;
  }
  @media (max-width: 300px) {
    font-size: 20px;
  }
`
export const DivImagensApoio = styled.div`
  margin-top: 24px;
  width: 352px;
  display: flex;
  flex: none;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 380px) {
    flex-direction: column;
    gap: 24px;
    align-items: center;
    width: 142px;
  }
`
export const ImagemApoio = styled.img<ICicomApoiaStylesProps>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  width: ${(props) => (props.width ? props.width : '152px')};
  height: ${(props) => (props.height ? props.height : '100px')};
  @media (max-width: 380px) {
    margin-top: 0px;
  }
`
