import React from 'react'
import {
  AreaApoio,
  DivApoio,
  TextoApoio,
  DivImagensApoio,
  ImagemApoio,
  ConteudoCicomApoia,
} from './styles'
import { LineDivisionSVG } from '../Svg/LineDivisionSVG'
import abrinq from '../../assets/imgs/abrinq.png'
import graac from '../../assets/imgs/graac.png'
import LazyLoad from 'react-lazyload'

interface ICicomApoiaProps {
  bgcolor?: string
}

function CicomApoia({ bgcolor }: ICicomApoiaProps) {
  return (
    <ConteudoCicomApoia id="CicomApoia" bgcolor={bgcolor}>
      <AreaApoio>
        <DivApoio>
          <TextoApoio>A CICOM apoia as causas:</TextoApoio>
          <LineDivisionSVG color={'var(--blue-600)'} />
        </DivApoio>
        <DivImagensApoio>
          <LazyLoad height={110} once>
            <ImagemApoio src={abrinq} width={'160px'} height={'110px'} />
          </LazyLoad>
          <LazyLoad height={110} once>
            <ImagemApoio src={graac} marginTop={'34px'} />
          </LazyLoad>
        </DivImagensApoio>
      </AreaApoio>
    </ConteudoCicomApoia>
  )
}

export { CicomApoia }
